import { isDev } from './helper.js';

let countryDropdown;

const init = () => {
    countryDropdown = document.querySelector(".dropdown-country");
    if (countryDropdown) {
        let channelToggle = countryDropdown.querySelector(".header-toggle-currency");
        if (channelToggle) channelToggle.addEventListener("click", loadHeaderChannels);
    }
};

const loadHeaderChannels = () => {
    if (!countryDropdown.classList.contains("loaded") && typeof window.nellyRR.getHeaderChannelsUrl !== "undefined") {
        let queryUrl = window.nellyRR.getHeaderChannelsUrl;
        fetch(queryUrl)
            .then(response => {
                return response.text();
            })
            .then(data => {              
                if (data.length > 2) {         
                    countryDropdown.querySelector(".dropdown-locale").innerHTML = data;
                    countryDropdown.classList.add("loaded");
                } else {
                    if (isDev) console.log('HeaderChannels response empty');
                }
            }).catch(error => {
                if (isDev) {
                    console.log('Failed to fetch headerChannels');
                    console.log(error);
                }               
            });
    }
};

// Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}