let cachedElements = {};

const init = () => {
    if (document.querySelector("#giftcard-page")) {

        // Init
        getElementsForCache();
        updateCountdown();

        //Bindings
        cachedElements.giftcardMessage.addEventListener('input', updateCountdown);

        // Events
        cachedElements.fixedValueItems.forEach(elem => elem.addEventListener("click", giftcardFixedValueClick));          
        //cachedElements.giftcardMethods.forEach(elem => elem.addEventListener("click", giftcardMethodClick));
        if (cachedElements.giftcardValue)
            cachedElements.giftcardValue.addEventListener("focus", giftcardValueFieldFocus);
        if (cachedElements.addGiftCardToCart)
            cachedElements.addGiftCardToCart.addEventListener("click", addGiftCardToCart);
    }
};

const getElementsForCache = () => {
    cachedElements.giftcardPage = document.querySelector("#giftcard-page");
    cachedElements.giftcardMethods = document.querySelectorAll(".giftcard-method");
    cachedElements.fixedValueItems = document.querySelectorAll(".giftcard-fixed-value-item");
    cachedElements.giftcardValue = document.querySelector("#giftcardValue");
    cachedElements.emailForm = document.querySelector(".giftcard-step.email");
    cachedElements.addGiftCardToCart = document.querySelector("#addGiftCardToCart");
    cachedElements.giftcardMessage = document.querySelector("#giftcardMessage");
    cachedElements.countdown = document.querySelector(".countdown");
};

const updateCountdown = () => {
    let innerInput = cachedElements.giftcardMessage.value;
    let remaining = 200 - innerInput.length;
    cachedElements.countdown.innerText = remaining;
};

const giftcardFixedValueClick = (e) => {

    let self = e.currentTarget,
        value = self.getAttribute("data-value");

    cachedElements.giftcardValue.value = value;
    cachedElements.fixedValueItems.forEach(elem => elem.classList.remove("selected"));
    self.classList.add("selected");
};

const giftcardValueFieldFocus = (e) => {
    cachedElements.fixedValueItems.forEach(elem => elem.classList.remove("selected"));
};

const addGiftCardToCart = (e) => {
    let self = e.currentTarget;

    self.disabled = true;

    let url = self.getAttribute("data-url"),
        value = cachedElements.giftcardValue.value,
        type = 2,
        message = document.querySelector("#giftcardMessage").value,
        name = document.querySelector("#giftcardName").value,
        email = document.querySelector("#giftcardEmail").value,
        regex = /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;

    if (value === undefined || value.trim() == "" ||
        parseInt(value) < parseInt(document.querySelector("#MinValueCard").value) ||
        parseInt(value) > parseInt(document.querySelector("#MaxValueCard").value)) {

        cachedElements.giftcardPage.classList.add("value-error");
        self.disabled = false;
        scrollToContainer("#giftcardValue", -250);
    }
    else if (!regex.test(email) && cachedElements.emailForm.classList.contains("active")) {
        cachedElements.giftcardPage.classList.remove("value-error");
        cachedElements.giftcardPage.classList.add("mail-error");
        self.disabled = false;
    } else {
        cachedElements.giftcardPage.classList.remove("mail-error");
        cachedElements.giftcardPage.classList.remove("value-error");
        var customerGiftcard = {
            Type: type,
            Value: value,
            Message: message,
            Name: name,
            Email: email
        };
        addProductsToCart(customerGiftcard, function () {
            nlyToastr(document.querySelector('#GiftcardAddedPrompt').value, { type: 'info', timeout: 5000 });
            self.disabled = false;            
        }, url);

    }
};

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
};