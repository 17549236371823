import { browserType } from './helper';

const recentTermsKey = `${browserType()}:search:recent`;

const storage = {
    getValue: () => {
        if (localStorage.getItem(recentTermsKey)) {
            return JSON.parse(localStorage.getItem(recentTermsKey));
        }
        else {
            localStorage.setItem(recentTermsKey, JSON.stringify({'terms': []}));
            return JSON.parse(localStorage.getItem(recentTermsKey));
        }
    },
    updateValue: (modifiedTerms) => {
        localStorage.setItem(recentTermsKey, JSON.stringify({ 'terms': modifiedTerms }));
    }
};

const recentSearchTemplate = (words) => {
    let html = `<div class="recent-search-text-title"><div class="content"><span>${alias("RECENT_SEARCHES")}</span><span class="remove-all-recent-search-terms">${alias("REMOVE")} ${alias("PRODUCT_INFO_ALL")}</span></div></div>`;
    for (let word of words) {
      html += `<li tabindex="-1" class="recent-search-text" data-term="${word}"><div class="content">${word}<span class="remove-recent-search-term close"></span></div></li>`;
    };

    return html;
}

const addToRecentSearchTerm = (term) => {
    let acceptedTerm;

    let isValid = () => {
        acceptedTerm = term.trim();

        return acceptedTerm.length > 2;
    }
    //invalid term
    if (!isValid(term)) {
        return;
    }

    let recentTerms = storage.getValue().terms;
    //is existing term
    if (recentTerms.indexOf(acceptedTerm) != -1) {
        return;
    }

    recentTerms.push(acceptedTerm);

    storage.updateValue(recentTerms);
}

const removeFromRecentSearchTerm = (term) => {
    let recentTerms = storage.getValue().terms;
    storage.updateValue(recentTerms.filter(e => e !== term));
    return recentTerms.length - 1;
}

const getRecentSearchTerms = () => {
    return storage.getValue().terms;
}

const resetRecentSearchTermsList = () => storage.updateValue([]);


export {
    recentSearchTemplate,
    getRecentSearchTerms,
    addToRecentSearchTerm,
    removeFromRecentSearchTerm,
    resetRecentSearchTermsList
}