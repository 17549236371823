
//const baseUrl = document.getElementById('baseUrl') ? document.getElementById('baseUrl').value : "";

//const wordAutocompleteTemplate = (words) => {
//    let html = "";
//    for (let word of words) {
//        html += `
//        <li tabindex="-1" class="autocomplete" data-term="${word.terms}">
//            <div class="content">
//                ${word.terms}
//            </div>
//        </li>
//        `;
//    };
//    return html;
//}

//const campaignAutocompleteTemplate = (campaigns) => {
//    let html = "";
//    html += `<div class="search-divider"><p>Campaigns</p><hr></div>`;
//    for (let campaign of campaigns) {
//        const campaignId = campaign.id.substr(campaign.id.indexOf('campaign_') + 9);
//        html += `
//        <li tabindex="-1">
//            <div class="sm-search">
//                <a href="${baseUrl}-campaign-${campaignId}">
//                    <div class="img-wrap">
//                        <img src="${alias('CAMPAIGN_TOP_SLIDER_' + campaignId)}" alt="${campaign.terms} Image">
//                    </div>
//                    <div class="text-wrap">
//                        ${campaign.terms}
//                    </div>
//                </a>
//            </div>
//        </li>
//        `;
//    };
//    return html;
//}

const productAutocompleteTemplate = (products) => {
    let html = "",
        i = 0;
    //html += `<div class="search-divider"><p>Products</p><hr></div>`;
    for (let product of products) {
        if (i == 10) break;
        html += `
        <li tabindex="-1" class="${product.label.includes("href") ? "searchlink" : "didyoumean"}" data-term="${product.label.includes("href") ? "" : product.label}">
            <div class="sm-search">
                    ${product.label}
                </div>
            </div>
        </li>
        `;
        i++;
    };
    return html;
}

export {
    //wordAutocompleteTemplate,
    //campaignAutocompleteTemplate,
    productAutocompleteTemplate
}