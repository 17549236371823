let cachedElements = {};

const init = () => {

    const sustainPage = document.querySelector(".sustainability");
    if (sustainPage) {
        getElementsForCache();
        document.querySelectorAll(".sustain-menu-btn").forEach(elem => elem.addEventListener("click", onOpenMenuClick));
        document.querySelectorAll(".sustain-landing dt").forEach(elem => elem.addEventListener("click", onInfoAccordionClick));
        document.querySelector(".sustain-menu-close-btn").addEventListener("click", onCloseMenu);
        document.querySelector(".body-overlay").addEventListener("click", onCloseMenu);
        window.addEventListener("resize", onResize);
    }
};

const getElementsForCache = () => {
    cachedElements.body = document.querySelector("body");
    cachedElements.menu = document.querySelector(".sustain-menu");
};

const onOpenMenuClick = () => {
    cachedElements.menu.classList.add("open");
    cachedElements.body.classList.add("menu-open");
};

const onCloseMenu = () => {
    cachedElements.menu.classList.remove("open");
    cachedElements.body.classList.remove("menu-open");
};

const onInfoAccordionClick = (e) => {
    const dt = e.currentTarget;
    const dd = dt.nextElementSibling;
    dt.classList.toggle("open");
    if (dd.style.maxHeight) {
        dd.style.maxHeight = null;
    } else {
        dd.style.maxHeight = dd.scrollHeight + "px";
    }
};

const onResize = () => {
    if (window.innerWidth < 992) {
        if (cachedElements.menu.classList.contains("open")) {
            cachedElements.body.classList.add("menu-open");
        }
    } else {
        cachedElements.menu.classList.remove("open");
    }
};

// Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}

