import { getRecommendationData, getRecommendationDataOnLoad } from './rr-recommendations';
import { isDev, paddedSku } from '../helper';
import { rrLoaded, hasUserAcceptedPersonalization, rrRecsEnabled } from './rr-helpers';
import { contentTracking, productTracking, trackClickedProduct } from './rr-tracking';
import { lazyloadSections } from './modules/rr-home-lazyload';

window.nellyRR = window.nellyRR || {};

// private methods
const init = () => {
   
    if (document.querySelector(".frontpage") != null) {
        lazyloadSections();
    }
    if (rrRecsEnabled) {
        getRecommendationDataOnLoad();
    }
    if (rrLoaded) {            
        document.addEventListener("mousedown", productTrackingClick, true);
        document.addEventListener("click", productTrackingClick, true);        
        if ('onpagehide' in window) {
            window.addEventListener("pagehide", onPageUnload, false);
        } else {
            window.addEventListener("unload", onPageUnload, false);
        } 
    }
    document.addEventListener("click", cookieBotAcceptClick);
};

// Event methods

const onPageUnload = () => { 
    trackClickedProduct();
};

const cookieBotAcceptClick = (e) => {
    if (!event.target.closest("#CybotCookiebotDialogBodyButtonAccept")) return;
    if (window.nellyRR.isLoggedIn == "true") {
        if (Cookiebot.consent.statistics == false) {
            if (isDev) console.log("RR - OptOut - Removing customer data");
            var options = {
                url: window.nellyRR.customerOptOutUrl
            };
            NellyGet(options);
        }
        else if (Cookiebot.consent.statistics == true) {
            if (isDev) console.log("RR - OptIn - Opting in customer");
            var options = {
                url: window.nellyRR.customerOptInUrl
            };
            NellyGet(options);
        }
    }
};

const productTrackingClick = (e) => {
    if (!e.target.closest(".js-rr-product-tracking")) return;
    productTracking(e);
};

// Private methods

const clearContext = () => {

    if (isDev) console.log("RR - Clearing context");

    if (typeof R3_HOME !== 'undefined') R3_HOME = undefined;
    if (typeof R3_CATEGORY !== 'undefined') R3_CATEGORY = undefined;
    if (typeof R3_SEARCH !== 'undefined') R3_SEARCH = undefined;
    if (typeof R3_ITEM !== 'undefined') R3_ITEM = undefined;
    if (typeof R3_ADDTOCART !== 'undefined') R3_ADDTOCART = undefined;
    if (typeof R3_CART !== 'undefined') R3_CART = undefined;
    if (typeof R3_PURCHASED !== 'undefined') R3_PURCHASED = undefined;
    if (typeof R3_BRAND !== 'undefined') R3_BRAND = undefined;
    if (typeof R3_GENERIC !== 'undefined') R3_GENERIC = undefined;
    if (typeof R3_ERROR !== 'undefined') R3_ERROR = undefined;
    if (typeof R3_PERSONAL !== 'undefined') R3_PERSONAL = undefined;
    if (typeof R3_WISHLIST !== 'undefined') R3_WISHLIST = undefined;
    if (typeof RR.registeredPageTypes.ADDTOCART !== 'undefined') RR.registeredPageTypes.ADDTOCART = undefined;

    R3_COMMON.placementTypes = '';
    R3_COMMON.categoryHintIds = '';
    R3_COMMON.itemIds = '';
    R3_COMMON.addedToCartItemIds = '';
};

const createAddToCartPage = (productSKU, productCategoryID, isCheckout) => {
    R3_COMMON.addPlacementType("add_to_cart_page");

    if (productCategoryID && productCategoryID != "") {
        R3_COMMON.addCategoryHintId(productCategoryID);
    }
    
    // GDPR - add privateMode if customer hasn't accepted personalization
    if (!hasUserAcceptedPersonalization()) {
        R3_COMMON.setPrivateMode(true);
    }

    var R3_ADDTOCART = new r3_addtocart();
    if (isCheckout) {
        R3_COMMON.addItemIdToCart(productSKU);
    } else {
        R3_ADDTOCART.addItemIdToCart(productSKU);
    }
};

const createItemPage = (productSKU, productName, productCategoryID) => {
    R3_COMMON.addPlacementType('item_page');
    if (productCategoryID && productCategoryID != "") {
        R3_COMMON.addCategoryHintId(productCategoryID);
    }
    // GDPR - add privateMode if customer hasn't accepted personalization
    if (!hasUserAcceptedPersonalization()) {
        R3_COMMON.setPrivateMode(true);
    }

    var R3_ITEM = new r3_item();
    R3_ITEM.setId(productSKU);
    R3_ITEM.setName(productName);
};

// public methods
const addToCart = (placements = "add_to_cart_page.nelly_recs_1", product = null) => {

    if (isDev) console.log("RR - addToCart");

    let quickbuyContainer = document.querySelector("#quickBuyContainer"),
        productSKU,
        productCategoryID;

    if (product) {
        const shortSku = product.articleNr + "-" + product.colorId;
        productSKU = paddedSku(shortSku);
        productCategoryID = product.categoryId && product.categoryId.length > 0 ? product.categoryId : null;
    } else if (quickbuyContainer) {
        productSKU = quickbuyContainer.querySelector("#productSku").value;
        productCategoryID = quickbuyContainer.querySelector("#productCategoryID") ? quickbuyContainer.querySelector("#productCategoryID").value : null;
    } else {
        productSKU = document.querySelector("#productSku").value;
        productCategoryID = document.querySelector("#productCategoryIDFromUrl") ? document.querySelector("#productCategoryIDFromUrl").value : null;
    }

    document.querySelector("#pageCategoryId") && !productCategoryID ? productCategoryID = document.querySelector("#pageCategoryId").value : "";

    // Call recs api
    if (rrRecsEnabled) {
        if (window.innerWidth >= 768) {
            getRecommendationData(placements, productSKU, productCategoryID);
        }
    }
    
    if (rrLoaded) {
        clearContext();
        createAddToCartPage(productSKU, productCategoryID);
        r3();
    }

};

const addToCartCheckout = (product) => {
    if (rrLoaded) {
        clearContext();
        if (isDev) console.log("RR - addToCart Checkout");
        const sizeId = product.sizeId;
        console.log(sizeId);
        const shortSku = product.articleNr + "-" + product.colorId;
        const productSKU = paddedSku(shortSku),
            productCategoryID = product.categoryId;

        createAddToCartPage(productSKU, productCategoryID, true);
        r3();
    }
};

const quickbuyOpen = () => {
    if (rrLoaded) {
        clearContext();

        if (isDev) console.log("RR - quickbuyOpen");

        let quickbuyContainer = document.querySelector("#quickBuyContainer"),
            productSKU = quickbuyContainer.querySelector("#productSku").value,
            productName = quickbuyContainer.querySelector("#productName").value,
            productCategoryID = quickbuyContainer.querySelector("#productCategoryID").value,
            sizeId = quickbuyContainer.querySelector("#productSizeId".value)

        console.log(sizeId);

        console.log(productSKU);

        createItemPage(productSKU, productName, productCategoryID);
        rr_flush_onload();
        r3();
    }
};

export {
    addToCart,
    quickbuyOpen,
    addToCartCheckout,
    contentTracking,
    productTracking
};

(function (window) {
    window.nellyRR = window.nellyRR || {};
    // Create rrObject for window
    const rr = {
        addToCart: addToCart,
        addToCartCheckout: addToCartCheckout,
        quickbuyOpen: quickbuyOpen,
        contentTracking: contentTracking,
        getRecommendationData: getRecommendationData
    }
    // Extend window.nellyRR with newly created RR object
    Object.assign(window.nellyRR, rr);
})(window);
// Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}
