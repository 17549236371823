import { elementScrolledIntoView, flowboxEnabled } from "./helper.js";

// Methods
const init = function() {
    const flowboxContainer = document.querySelector("#js-flowbox-flow");

    if (flowboxContainer && flowboxEnabled) {
        elementScrolledIntoView(flowboxContainer, flowboxContainer.getAttribute("data-scroll"), loadFlowBox);
    }
};
const loadFlowBox = (flowboxContainer) => {

    const key = flowboxContainer.getAttribute("data-key");
    const sku = flowboxContainer.getAttribute("data-sku");
    const tags = flowboxContainer.getAttribute("data-tags");

    (function (d, id) {
        if (!window.flowbox) { var f = function () { f.q.push(arguments); }; f.q = []; window.flowbox = f; }
        if (d.getElementById(id)) { return; }
        var s = d.createElement("script"), fjs = d.scripts[d.scripts.length - 1]; s.id = id; s.async = true;
        s.src = ' https://connect.getflowbox.com/flowbox.js';
        fjs.parentNode.insertBefore(s, fjs);
    })(document, "flowbox-js-embed");

    let locale = "sv-SE";
    if (document.getElementById("locale")) {
        locale = document.getElementById("locale").value;
    }
    window.flowbox("init",
        {
            container: "#js-flowbox-flow",
            productId: sku,
            key: key,
            tags: [tags],
            locale: locale,
            noLazyLoad: true
        });
};

const filterFlowBox = (tags) => {
    const flowboxContainer = document.querySelector("#js-flowbox-flow");
    if (flowboxContainer && flowboxEnabled) {
        window.flowbox("update",
            {
                key: flowboxContainer.getAttribute("data-key"),
                tags: [tags]
            });
    }
};

window.filterFlowBox = filterFlowBox;

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init); 
}