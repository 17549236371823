import { imageSrcSet } from '../rr-helpers.js';
import { soldOutTemplate } from '../../soldout-template.js';

const productPageRecsTemplate = (items, strategyMessage) => {
    return `
        <h4>${strategyMessage}</h4>
        <ul class="pdp-xsell-list list-unstyled">
            ${productRecsItemTemplate(items)}
        </ul>
    `;
}

const productRecsItemTemplate = (products) => {
    let html = "",
        i = 0;

    for (let product of products) {
        if (i == 4) break;

        let idArray = product.id.split("-"),
            articleId = idArray[0],
            colorId = idArray[1],
            productLink = product.attributes.link_url.toString().replace("nelly.com", window.location.host);

        let categoryGroup = product.categories.filter(({ id }) => id.includes("nelly_group")),
            categoryStyle = product.categories.filter(({ id }) => id.includes("nelly_style")),
            categoryID = "",
            categoryName = "";

        if (Array.isArray(categoryStyle) && categoryStyle.length > 0) {
            categoryID = categoryStyle[0].id;
            categoryName = categoryStyle[0].name;
        } else if (Array.isArray(categoryGroup) && categoryGroup.length > 0) {
            categoryID = categoryGroup[0].id;
            categoryName = categoryGroup[0].name;
        }

        html += `
            <li class="pdp-xsell-list-item js-rr-recs-item js-rr-product-tracking GA_ProductData" data-shortsku="${product.id}" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-brand="${product.brand}" data-name="${product.name}" data-price="" data-ordprice="" data-category="${categoryName}" data-categoryid="${categoryID}">
                <div class="pdp-xsell-image-container">
                    <a href="${productLink}" class="GA_Product">
                        <picture>
                            <source type="image/webp" data-srcset="${imageSrcSet(product.imageURL, true)}" />                                 
                            <img width="276" height="368" class="lazyload img-responsive" alt="${product.name}" data-sizes="auto" 
                                    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 212 282'%2F%3E"
                                    data-srcset="${imageSrcSet(product.imageURL)}">
                        </picture>
                    </a>
                    <a href="#" class="pdp-xsell-quickview hide-quickbuy-mb js-quickbuy js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}">${window.aliasTexts.quickViewText}</a>
                    <a href="#" class="btn-quickadd btn-pink js-quickadd js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-categoryid="${categoryID}">
                        <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>
                    </a> 
                    ${soldOutTemplate(articleId, colorId)}
                    ${window.nellyRR.showInspirationlist == "true" ?
                `
                        <button class="btn-like js-fav">
                            <i class="icon heart-like"                               
                                data-articlenr="${articleId}"
                                data-colorid="${colorId}"
                                data-isloggedin="${window.nellyRR.isLoggedIn}"></i>
                        </button>
                    ` : ""}

                    ${(window.nellyRR.showWishlistFunctions == "true" && window.nellyRR.isLoggedIn == "true") ?
                `
                        <button class="btn-wish js-wish">
                            <i class="icon heart-like wish-like"
                                data-articlenr="${articleId}"
                                data-colorid="${colorId}"
                                data-isloggedin="${window.nellyRR.isLoggedIn}"></i>
                        </button>
                    ` : ""}
                    </div>
                    <a href="${productLink}" class="GA_Product">
                        <div class="pdp-xsell-product-information">
                            <div class="pdp-xsell-title-container">
                                <span class="pdp-xsell-product-text pdp-xsell-product-brand" title="${product.brand}">${product.brand}</span>
                                <span class="pdp-xsell-product-text pdp-xsell-product-name" title="${product.name}">${product.name}</span>
                            </div>
                          </div>                
                    </a>
                    <div class="pdp-xsell-price-container js-rr-price-container">
                        <span style="visibility:hidden;">${product.priceCents}</span> 
                    </div>
                <div class="color-picker js-color-picker"></div>
            </li>
            `;
        i++;
    };
    return html;
};

const productRecsPriceTemplate = (product) => {
    let html = "";
    html += `     
            <div class="pdp-xsell-price">
                <div class="pdp-xsell-price-original-container pdp-xsell-price-sale-container">
                    <span class="pdp-xsell-price-original${product.IsDiscount ? " is-sale" : ""}">${product.Price}&nbsp;</span>
                
                ${product.IsDiscount ?
                `                    
                        <span class="pdp-xsell-price-sale-original">${product.OrdinaryPrice}</span>
                        <span class="pdp-xsell-price-sale-percentage">${product.Discount}</span>
                   
                ` : ``
                } </div>
            </div>
        `
    return html;
}

export {
    productPageRecsTemplate,
    productRecsPriceTemplate
}
