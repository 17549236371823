const isDev = process.env.NODE_ENV == "development";
const flowboxEnabled = (typeof window.nellyRR != "undefined" && typeof window.nellyRR.flowBoxEnabled != "undefined" && window.nellyRR.flowBoxEnabled == "true");
const featureFirebaseFirestoreEnabled = (typeof window.nellyRR != "undefined" && typeof window.nellyRR.featureFirebaseFirestoreEnabled != "undefined" && window.nellyRR.featureFirebaseFirestoreEnabled == "true");

// Private methods //
const _observeElement = (elem, offset, callback) => {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (callback) callback(entry.target);
                observer.unobserve(entry.target);
                _elementReached(entry.target);
            }
        });
    }, { rootMargin: `0px 0px ${offset}px 0px` });

    // Observe Multiple Elements
    if (typeof elem !== "undefined" && elem.length > 0) {
        elem.forEach(entry => {
            observer.observe(entry);
        });
    // Observe Single Element
    } else {
        observer.observe(elem);
    } 
};

const _elementReached = (elem) => {
    elem.classList.add("element-lazyloaded");
};


var cachedValues = cachedValues || {};
try {
    cachedValues.shoppingBagCounters = document.querySelectorAll('.shopping-cart-count');
    cachedValues.miniCartPrice = document.getElementById('mini-cart-price');
    cachedValues.miniCartContent = document.getElementById('mini-cart-content');
    cachedValues.quickAddSizes = document.getElementById('quickadd_sizes');
    cachedValues.favouriteCounters = document.querySelectorAll('.favoriteCounter');
    cachedValues.miniCartButtons = document.querySelectorAll('.js-cart-btn-container');
    cachedValues.goToCartButton = document.getElementById('HeaderGoToCart');
    cachedValues.channelId = document.getElementById('nelly_channelId').value;
} catch (e) {
    console.log(e);
}

const _getCartAndFavourites = (successCallback, errorCallback) => {
    const url = document.getElementById('CartAndFavoritesCountUrl').dataset.url;
    fetch(url, {
        method: 'post'
    })
    .then(res => res.json())
    .then(data => {
        if (successCallback && typeof successCallback === "function") { successCallback(data); }
    })
    .catch(error => {
        if (errorCallback && typeof errorCallback === "function") { errorCallback(error); }
    });
};

// End Private Methods //

// Exported methods //
const AmplienceHost = {
    setUrl: "https://media.nelly.com/s/nlyscandinavia/",
    iUrl: "https://media.nelly.com/i/nlyscandinavia/",
    originalUrl: "https://content.nelly.com/v1/static/"
};

const queryStringBuilder = (params) => {
    var queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');
    return queryString;
};

const isSendBeaconSupported = () => {
    const windowObject = typeof window === 'object' ? window : this;
    return ('navigator' in windowObject) && ('sendBeacon' in windowObject.navigator);
};

const isElementVisible = (elem, offset) => {
    const bounding = elem.getBoundingClientRect();
    return bounding.top <= (window.innerHeight || document.documentElement.clientHeight) + offset;
};

const elementScrolledIntoView = (elem, offset, callback) => {
    let targetElement,
        elemOffset = offset || 0;

    // If Element(s)
    if (typeof elem.nodeType !== "undefined" || (typeof elem !== "undefined" && elem.length > 0)) {
        targetElement = elem;
    // If String (class or id)
    } else {
        targetElement = document.querySelector(elem);
    }

    if (targetElement) {    
        _observeElement(targetElement, elemOffset, callback);
    }
};

const paddedSku = (sku) => {
    sku = sku.toString().trim();
    if (sku.includes('-')) {
        const skuParts = sku.split('-');
        if (skuParts[2]) {
            return skuParts[0].padStart(6, '0') + '-' + skuParts[1].padStart(4, '0') + '-' + skuParts[2].padStart(3, '0');
        }
        return skuParts[0].padStart(6, '0') + '-' + skuParts[1].padStart(4, '0');
    }
    return sku.padStart(6, '0');
};

const loadScript = (src, callback, async, id) => {

    let d = document,
        tag = 'script',
        newScript = d.createElement(tag),
        firstScript = d.getElementsByTagName(tag)[0];

    if (id) {
        newScript.id = id;
    }

    newScript.src = src;

    if (async) {
        newScript.async = true;
    }
    if (callback) {
        // IE support
        newScript.onreadystatechange = () => {
            if (newScript.readyState === 'loaded' || newScript.readyState === 'complete') {
                newScript.onreadystatechange = null;
                callback();
            }
        }
        // Other (non-IE) browsers support
        newScript.onload = () => {
            callback();
        }
    }
    firstScript.parentNode.insertBefore(newScript, firstScript);
};

const addProductToCart = ({ articleNr, colorId, sizeId, quantity = 1 }, successCallback, errorCallback) => {
    const product = [{
        ArticleNr: articleNr,
        ColorId: colorId,
        SizeId: sizeId,
        Quantity: quantity
    }];
    const addProductUrl = document.getElementById('addProductsToCart').value;
    fetch(addProductUrl, {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(product)
    })
    .then(res => res.json())
        .then(data => {
            if (successCallback && typeof successCallback === "function") { successCallback(data); }
    })
    .catch(error => {
            if (errorCallback && typeof errorCallback === "function") { errorCallback(error); }
    });
};


const updateCartAndFavourites = (successCallback, errorCallback) => {
    _getCartAndFavourites((data) => {
        try {
            cachedValues.shoppingBagCounters.forEach(counter => counter.textContent = data.Data.CartCount);
            var date = new Date();
            var minutes = 60;
            date.setTime(date.getTime() + (minutes * 60 * 1000));

            $.cookie("CartPreLoadCookie" + cachedValues.channelId, data.Data.CartCount, { path: '/', expires: date });
            cachedValues.favouriteCounters.forEach(counter => counter.textContent = data.Data.FavoriteCount);
            cachedValues.miniCartPrice.textContent = data.Data.SubTotalSum;
            cachedValues.miniCartContent.innerHTML = data.Data.MiniCartContent;
            if (data.Data.CartCount < 1) {
                cachedValues.goToCartButton.classList.add('hidden');
                cachedValues.miniCartButtons.forEach(button => button.classList.remove('has-items'));
            } else {
                cachedValues.goToCartButton.classList.remove('hidden');
                cachedValues.miniCartButtons.forEach(button => button.classList.add('has-items'));
            }

        } catch (error) {
            if (errorCallback && typeof errorCallback === "function") { errorCallback(error); }
        }

        if (successCallback && typeof successCallback === "function") { successCallback(data); }
    }, (error) => {
        console.log(error);
        if (errorCallback && typeof errorCallback === "function") { errorCallback(error); }
    });
};

const browserType = () => {
    const browserType = document.querySelector("#browserType");     
    return browserType ? browserType.value : "";
};

const getOsData = () => {
    var ua = navigator.userAgent.toLowerCase();
    var osData = {};
    if (ua.match(/android/i)) {
        osData = { os: "android" };
    }
    else if (ua.match(/ipad/i) || ua.match(/iphone/i)) {
        osData = {
            os: "ios",
            version: ua.substr(ua.indexOf("os ") + 3, 3).replace("_", ".")
        };
    } else {
        osData = { os: "desktop" };
    }
    return osData;
};

const getProductDataUrl = (skus, params = []) => {
    const productDataUrl = document.getElementById('getProductData');
    if (!productDataUrl) return false;
    var joinedString = '';
    skus.forEach((sku, i) => {
        joinedString += (i === 0) ? '?shortSkus=' + sku.trim() : '&shortSkus=' + sku.trim();
    });
    params.forEach((param) => {
        joinedString += '&' + param.trim() + '=true';
    });
    return productDataUrl.value + joinedString;  
};

const getProductDataHeloUrl = (skus, params = []) => {
    const productDataUrl = document.getElementById('getProductDataHelo');
    if (!productDataUrl) return false;
    var joinedString = '';
    skus.forEach((sku, i) => {
        joinedString += (i === 0) ? '?shortSkus=' + sku.trim() : '&shortSkus=' + sku.trim();
    });
    params.forEach((param) => {
        joinedString += '&' + param.trim() + '=true';
    });
    return productDataUrl.value + joinedString;
};

const getProductData = (skus, params, callback) => {
    const queryUrl = getProductDataUrl(skus, params);
    fetch(queryUrl).then(response => {
        response.json().then(data => {
            if (data) {
                callback(data);
            } else {
                console.log('failed to fetch products');
            }
        });
    });
};

const getSimilarProductsUrl = (shortsku, params = [], styleId, groupId, classId) => {
    if (typeof window.nellyRR.getSimilarProductsUrl !== "undefined") {
        var parameters = '';
        params.forEach((param) => {
            parameters += '&' + param.trim() + '=true';
        });      
        return window.nellyRR.getSimilarProductsUrl + '?ShortSku=' + shortsku + parameters + '&StyleId=' + styleId + '&GroupId=' + groupId + '&ClassId=' + classId;
    }
};

const getProductDataHelo = (skus, params, callback) => {
    const queryUrl = getProductDataHeloUrl(skus, params);
    fetch(queryUrl).then(response => {
        response.json().then(data => {
            if (data) {
                callback(data);                
            } else {
                console.log('failed to fetch products');
            }
        });
    });
};

// End Exported methods //

// Public methods //
window.elementScrolledIntoView = elementScrolledIntoView;
window.isElementVisible = isElementVisible;
window.paddedSku = paddedSku;
window.loadScript = loadScript;
window.browserType = browserType;
window.getProductData = getProductData;
// End Public Methods //

export {
    AmplienceHost,
    isDev,
    queryStringBuilder,
    isSendBeaconSupported,
    elementScrolledIntoView,
    isElementVisible,
    paddedSku,
    loadScript,
    flowboxEnabled,
    addProductToCart,
    updateCartAndFavourites,
    browserType,
    getOsData,
    getProductDataUrl,
    getProductDataHeloUrl,
    getProductData,
    getProductDataHelo,
    getSimilarProductsUrl,
    featureFirebaseFirestoreEnabled
}