import { featureFirebaseFirestoreEnabled } from "./helper.js";

const init = function () {
    var element = document.getElementById("firebase_active");

    if (element && featureFirebaseFirestoreEnabled)  {
        loadFirebase($.cookie("CustomerLoginId"));
    }
};

const loadFirebase = (publicCustomerId) => {

    (function (d, id) {
        if (!window.firebaseapp) { var f = function () { f.q.push(arguments); }; f.q = []; window.firebaseapp = f; }
        if (d.getElementById(id)) { return; }
        var s = d.createElement("script"), fjs = d.scripts[d.scripts.length - 1]; s.id = id; s.async = true;
        s.src = 'https://www.gstatic.com/firebasejs/7.14.6/firebase-app.js';
        fjs.parentNode.insertBefore(s, fjs);
    })(document, "firebase-app");

    (function (d, id) {
        if (!window.firebaseappstore) { var f = function () { f.q.push(arguments); }; f.q = []; window.firebaseappstore = f; }
        if (d.getElementById(id)) { return; }
        var s = d.createElement("script"), fjs = d.scripts[d.scripts.length - 1]; s.id = id; s.async = true;
        s.src = 'https://www.gstatic.com/firebasejs/7.14.6/firebase-firestore.js';
        fjs.parentNode.insertBefore(s, fjs);
    })(document, "firebase-app-firestore");
    setTimeout(function () {
        firebase.initializeApp({
            apiKey: 'AIzaSyCb4bPOPJFIIfIxaAikWXgwkziawpfPRw0',
            authDomain: 'nelly-b5134.firebaseapp.com',
            projectId: 'nelly-b5134'
        });

        console.log("firebase loaded");
        var db = firebase.firestore();


        var custRef = db.collection("customers").doc(publicCustomerId).collection("updates");



        custRef.where("ValidUntilUTC", ">", ((new Date().getTime() * 10000) + 621355968000000000)).orderBy("ValidUntilUTC", "desc").limit(1)
            .onSnapshot(function (doc) {
                doc.forEach(function (doc) {

                    if (!$.cookie(doc.data().MessageId)) {
                        var element = document.getElementById("firebase_active");
                        var url = element.getAttribute('data-url');
                        fetch(url + '?audience=' + doc.data().Audience + "&messageId=" + doc.data().MessageId)
                            .then(resp => resp.json())
                            .then(function (data) {
                                if (data.action.ActionType === 1) {
                                    var s = document.createElement("script");
                                    s.innerHTML = data.action.Action;
                                    document.body.appendChild(s);
                                    if (typeof runFirebaseAction === "function")
                                        runFirebaseAction(data.action.Audience, data.action.MessageId);
                                }
                            });
                    } else {
                        console.log("message already seen");
                    }

                });




            });

    }, 3000);

};

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}