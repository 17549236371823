import { isDev, elementScrolledIntoView } from '../../helper.js';

let sectionElems;

const lazyloadSections = () => {
    sectionElems = document.querySelectorAll(".section-lazyload");
    if (sectionElems.length > 0) {
        elementScrolledIntoView(sectionElems, 300, loadSectionContent);       
    }
};

const loadSectionContent = (section) => {

    section.classList.remove("section-lazyload");
    const rowNumber = section.getAttribute("data-rownumber");
    section.classList.add("section-lazyloading");

    const onSuccess = (html) => {
        section.innerHTML = html;
        section.classList.remove("section-lazyloading");
        section.classList.add("section-lazyloaded");
    }

    const onEmpty = () => {
        section.classList.remove("section-lazyloading");
        section.classList.add("section-empty");
    }

    const onError = (error) => {
        section.classList.remove("section-lazyloading");
        section.classList.add("section-empty");
    }

    getFrontPageRow(rowNumber, onSuccess, onEmpty, onError);
};

const getFrontPageRow = (rowNumber, onSuccess, onEmpty, onError) => {
    if (typeof window.nellyRR.getFrontPageRowUrl !== "undefined" && rowNumber) {   
        let queryUrl = window.nellyRR.getFrontPageRowUrl + "?rowNumber=" + rowNumber;
        fetch(queryUrl)
        .then(response => {
            return response.text();
        })
        .then(data => {
            if (onSuccess && data.length > 2) {
                onSuccess(data);
            } else {
                if (onEmpty) onEmpty();
            }       
        }).catch(error => {
            if (isDev) console.log('failed to fetch front page row ' + rowNumber);
            if (onError) onError(error);
        });
    }
};

export {
    lazyloadSections
}
