import { elementScrolledIntoView, isDev } from "../../Components/helper";

const init = () => {
    if (document.querySelector(".product-overview-news") != null) {
        let newsItems = document.querySelectorAll(".news-item-lazyload");
        if (newsItems.length > 0) {
            elementScrolledIntoView(newsItems, 100, getNewsFromClassId);
        }
    }
};

const getNewsFromClassId = (elem) => {

    let classId = elem.querySelector(".paramProductClassType").value,
        newsItemContainer = elem.querySelector(".news-items-container"),
        page = 1;

    if (typeof GetQueryString().classid !== "undefined" && typeof GetQueryString().page !== "undefined") {
        if (classId == GetQueryString().classid) {
            page = parseInt(GetQueryString().page);
        }       
    }

    const queryUrl = document.querySelector("#getNewsFromClassIdUrl").getAttribute("data-url");
    const options = {
        method: 'POST',
        body: JSON.stringify({ classId: classId, page: page }),
        headers: {
            'Content-Type': 'application/json'
        }
    }

    fetch(queryUrl, options).then(response => {
        response.json().then(data => {
            if (data && data.productList) {
                newsItemContainer.innerHTML = data.productList;
                window.loadColorImages();
            }
        }).catch(error => {
            if (isDev) console.log('failed to fetch newsitem with classId: ' + classId);
        });;
    });
};

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}