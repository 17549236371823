
import { isDev } from './helper';
import { productAutocompleteTemplate } from './RichRelevance/modules/rr-search';
import {
  addToRecentSearchTerm,
  getRecentSearchTerms,
  recentSearchTemplate,
  removeFromRecentSearchTerm,
  resetRecentSearchTermsList
} from './search-recent-terms';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

let trendingSearchTerms = null;
let isLoggedIn = $('#isLoggedIn').val() === "1";

const fetchTrendingSearchTerms = (form) => {
    return fetch(form.dataset.trendingterms)
        .then(response => response.json())
        .then(data => {
            if (data && data.length > 0) {
                trendingSearchTerms = productAutocompleteTemplate(data);
                return trendingSearchTerms;
            }
        }, (error) => {
                if (isDev) console.log(error);
        });
};

const populateTrendingSearchTerms = (form) => {
    const productsResultContainer = form.querySelector('.search-ac-products');

    if ($("#showTrendingSearchList").data("active") === "True") {
        (trendingSearchTerms && trendingSearchTerms.length ? Promise.resolve() : fetchTrendingSearchTerms(form))
            .then(() => {
                if (trendingSearchTerms && trendingSearchTerms.length) {
                    if (!form.classList.contains('searching')) { form.classList.add('searching'); }

                    productsResultContainer.innerHTML = trendingSearchTerms;

                    if (!productsResultContainer.classList.contains('found')) { productsResultContainer.classList.add('found'); }
                    if (!form.classList.contains('found')) { form.classList.add('found'); }
                }
            });
    }
};

const populateRecentSearchTerms = (form) => {
    const wordsResultContainer = form.querySelector('.search-ac-words');
    let recentTerms = getRecentSearchTerms();
    if (isLoggedIn && recentTerms.length) {
        wordsResultContainer.innerHTML = recentSearchTemplate(recentTerms.splice(0, 10));
        if (!wordsResultContainer.classList.contains('found')) { wordsResultContainer.classList.add('found'); }
        if (!form.classList.contains('found')) { form.classList.add('found'); }
    }
}

function sendQuickSearchToDataLayer(action, label) {
    window.dataLayer.push({
        'event': 'quick_search',
        eventInfo: {
            'category' : 'Search',
            'action' : action,
            'label' : label,
        }
    });
}

const searchCatalog = (form) => {

    const wordsResultContainer = form.querySelector('.search-ac-words'),
        campaignsResultContainer = form.querySelector('.search-ac-campaigns'),
        productsResultContainer = form.querySelector('.search-ac-products'),
        //autocompleteUrl = form.dataset.autocomplete,
        productUrl = form.dataset.searchurl;

    wordsResultContainer.innerHTML = "";
    campaignsResultContainer.innerHTML = "";
    productsResultContainer.innerHTML = "";

    wordsResultContainer.classList.remove("found");
    campaignsResultContainer.classList.remove("found");
    productsResultContainer.classList.remove("found");
    form.classList.remove("found");

    const searchValueField = form.querySelector("#search-value") || form.querySelector("#search-value-mobile");

    let searchQuery = searchValueField.value.trim();
    if (searchQuery.indexOf('&') > -1) {
        searchQuery = searchQuery.replace('&', '%26');
    }

    if (isDev) console.log("searching");

    if (!searchQuery.length || searchQuery.length < 0) {
        form.classList.remove('searching');
       // populateTrendingSearchTerms(form); Though this is controlable from SHOW_TRENDING_SEARCH_LIST config, i keeping this commented as its not released as a feature
        populateRecentSearchTerms(form);
        return;
    } else {
        form.classList.add('searching');

        //word autocomplete
        /*
        fetch(autocompleteUrl + "&query=" + searchQuery + (nellyRR.pcId ? "&userId=" + nellyRR.pcId : ''))
            .then(response => response.json())
            .then((data) => {
                if (isDev) console.log(data);
                if (data.length) {
                    let wordData = [];
                    let campaignData = [];
                    data.forEach((obj) => {
                        obj.value = obj.terms;
                        !obj.id.includes('campaign') ? wordData.push(obj) : null;
                        obj.id.includes('campaign') ? campaignData.push(obj) : null;
                    });
                    if (wordData.length) {
                        wordsResultContainer.innerHTML = wordAutocompleteTemplate(wordData.splice(0, 5));
                        wordsResultContainer.classList.add("found");
                        form.classList.add("found");
                    }
                    if (campaignData.length) {
                        campaignsResultContainer.innerHTML = campaignAutocompleteTemplate(campaignData.splice(0, 3));
                        campaignsResultContainer.classList.add("found");
                        form.classList.add("found");
                    }
                }
            }, (error) => {
                // handle network error
                if (isDev) console.log(error);
            });
        */

        //product autocomplete
        fetch(productUrl + "?term=" + searchQuery)
            .then(response => response.json())
            .then((data) => {
                if (isDev) console.log(data);
                if (data.length) {
                    //const products = data.filter((obj) => {
                    //    return !obj.id.includes('didyoumean'); //no categories
                    //});
                    productsResultContainer.innerHTML = productAutocompleteTemplate(data);
                    productsResultContainer.classList.add("found");
                    form.classList.add("found");

                    sendQuickSearchToDataLayer('Quick search result clicks', searchQuery)
                } else {
                    sendQuickSearchToDataLayer('Quick search zero results', searchQuery)
                    // No results
                    console.log("NO RESULTS")
                }
            }, (error) => {
                if (isDev) console.log(error);
            });
    }
};

const submitSearch = (form, event) => {
    if (event && event.target) {
        let searchField = event.target.querySelector("#search-value") || event.target.querySelector("#search-value-mobile");
        if (searchField && searchField.value && isLoggedIn) {
            addToRecentSearchTerm(searchField.value);
        }
    }

    const el = document.getElementsByClassName("link-result");
    if (el) {
        let url = document.getElementsByClassName("link-result")[0];
        if (url) {
            event.preventDefault();
            url ? window.location.href = url.href : null;
        }
        
    }

    const results = document.getElementsByClassName("searchlink");
    if (results && results.length === 1) {
        let url = document.getElementsByClassName("search-product-url")[0];
        if (url) {
            event.preventDefault();
            url ? window.location.href = url.href : null;
        }
    }
};

const selectInSearch = (form, event) => {
    const el = event.target;
    if (isDev) console.log(el);
    const searchValueField = form.querySelector("#search-value") || form.querySelector("#search-value-mobile");
    if (el.closest(".search-clear")) {
        searchValueField.value = "";
        searchValueField.focus();
        searchCatalog(form);
        return;
    }
    if (el.closest(".search-icon")) {
        searchValueField.value = "";
        document.body.classList.remove('menu-open');
        form.classList.remove('expanded');
        searchCatalog(form);
        return;
    }
    if (el.closest(".autocomplete")) {
        searchValueField.value = el.closest(".autocomplete").dataset.term.trim();
        form.submit();
        return;
    }
    if (el.closest('.remove-recent-search-term')) {
        var valueToRemove = el.closest(".recent-search-text").dataset.term.trim();
        var remainingTerms = removeFromRecentSearchTerm(valueToRemove);
        $(el.closest(".recent-search-text")).remove();
        if (!remainingTerms) {
            resetRecentSearchTermsArea(form);
        }
        return;
    }
    if (el.closest('.remove-all-recent-search-terms')) {
        resetRecentSearchTermsList();
        resetRecentSearchTermsArea(form);
        return;
    }
    if (el.closest(".recent-search-text")) {
        searchValueField.value = el.closest(".recent-search-text").dataset.term.trim();
        form.submit();
        return;
    }
    if (el.closest(".didyoumean")) {
        searchValueField.value = el.closest(".didyoumean").dataset.term.trim();
        form.submit();
        return;
    }
    if (el.closest(".searchlink")) {
        let url = null;
        el.href ?
            url = el.href :
            (el.querySelector('a') ?
                url = el.querySelector('a').href :
                null);
        url ? window.location.href = url : null;
    }
};

const initSearch = debounce((form) => {
    searchCatalog(form);
}, 400);

const searchFields = [
    document.getElementById("search-form"),
    document.getElementById("search") ? document.getElementById("search").querySelector('form') : null
];

const resetRecentSearchTermsArea = (form) => {
    const wordsResultContainer = form.querySelector('.search-ac-words');
    $(".recent-search-text-title").remove();
    $(".recent-search-text").remove();
    form.classList.remove('found');
    wordsResultContainer.classList.remove('found');
}

//remove and replace with a method identical to GetQueryString in helper.js attached to window
const getQueryString = (name, url) => {
    if (!url) url = window.location.href;
    if (!name) return null;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const init = () => {
    function handleQuickSearch(searchField) {
        const searchTerm = $(searchField).val().trim()
        if (!searchTerm) {
            return
        }
        sendQuickSearchToDataLayer('Quick search result impression', searchTerm)
    }

    searchFields.forEach((form) => {
        if (form) {
            form.addEventListener('input', (event) => { initSearch(form); });
            form.addEventListener('click', (event) => { selectInSearch(form, event); });
            form.addEventListener('submit', (event) => { submitSearch(form, event) });

            const searchValueField = form.querySelector("#search-value") || form.querySelector("#search-value-mobile");
            searchValueField.addEventListener('focus', (event) => {
                form.classList.add('focus-within');
                document.addEventListener("keyup", event => {
                    if (event.keyCode === 27 && window.innerWidth >= 992) {
                        form.classList.remove('expanded');
                    }
                });
                document.body.classList.add('menu-open');
                form.classList.add('expanded');

                document.querySelector('.navbar-toggle[data-target="#navigation"]').classList.add('collapsed');
                document.getElementById('navigation').classList.remove('in');
            });

            searchValueField.addEventListener('blur', (event) => {
                form.classList.remove('focus-within');
            });
            populateRecentSearchTerms(form);

            $(form).on("click", ".sm-search", function () {
                handleQuickSearch(searchValueField)
            })
           // populateTrendingSearchTerms(form); Though this is controlable from SHOW_TRENDING_SEARCH_LIST config, i keeping this commented as its not released as a feature
        }
    });
};

const onScroll = () => {
    var y = window.pageYOffset;
    if (y > 10) {
        if (document.querySelector("body.sg-fixed-search") == null) {
            document.querySelector("body").classList.add("sg-fixed-search");
        }
    } else {
        if (document.querySelector("body.sg-fixed-search") != null) {
            document.querySelector("body").classList.remove("sg-fixed-search");
        }
    }
};

// Scroll event
window.addEventListener("scroll", throttle(onScroll, 66), false);

// Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}
