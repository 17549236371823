
import { paddedSku } from '../../Components/helper';

const sizesList = (data) => {
    let listItems = "";
    data.Sizes.forEach(size => {
        var comingSoon = size.SoldOut && size.ComingSoon;
        var soldOut = size.SoldOut && !size.ComingSoon;
        listItems += `<li class="quickadd-size ${size.SoldOut ? `size-soldout hidden` : ``} ${comingSoon ? `size-comingsoon` : ``}" data-sizeid="${size.Id}" data-articlenr="${data.ArticleNr}" data-colorid="${data.ColorId}" data-categoryid="${data.CategoryId}" data-sorting="${size.SortOrder}" data-sizeName="${size.Name}">
            <span class="size-name">
                ${size.Name} 
                ${size.FewLeft ? `
                    <span class="size-info info-fewleft">- ${window.aliasTexts.fewLeftSizeText}</span>`
                : ``}
                ${soldOut ? `
                    <span class="hidden">- ${window.aliasTexts.soldOutText}</span>`
                : ``}
                ${comingSoon ? `
                    <span class="size-info info-comingsoon">- ${window.aliasTexts.comingSoonText}</span>`
                : ``}
            </span>
            ${size.SoldOut ? `
                <a href="javascript:;" class="hidden">${window.aliasTexts.monitorSizeText}</a>`
                :``}
            ${comingSoon || soldOut ? `` :
            `<a href="javascript:;" class="size-btn add-size-btn btn-pink"> ${window.aliasTexts.addToShortText}<i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i></a>`}
        </li>`;
    });

    return `<div class="quickadd-sizes-header">
        <span>${window.aliasTexts.selectSizeToAddText}</span>
    </div>
    <ul class="quickadd-sizes-list">
        ${listItems}
    </ul>`;
};

const noSizesList = () => {
    return `<div class="quickadd-sizes-header nosizes">
        <span>${window.aliasTexts.generalErrorText}</span>
    </div>`;
};

const shoppingBagCount = (count) => {
    return `<i class="icon fubar-bag"></i><span class="notification-badge shopping-cart-count">${count}</span>`;
};

const gaProductDataTag = (product) => {
    const shortSku = paddedSku(`${product.articleNr}-${product.colorId}`);
    return `<input type="hidden" id="GA_productQuickAddDetail" data-articlenumber="${shortSku}" data-name="${product.name}" data-price="${product.price}" data-ordprice="${product.ordPrice}" data-sessioncode="${product.sessionCode}" data-brand="${product.brand}" data-category="${product.category}">`;
};

export {
    noSizesList,
    sizesList,
    gaProductDataTag,
    shoppingBagCount
};