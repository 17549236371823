import { imageSrcSet } from '../rr-helpers.js';
import { soldOutTemplate } from '../../soldout-template.js';

const categoryRecs1Template = (items, strategyMessage) => {
    return `
        <div id="top-seller-list">
            <h4 class="topsellers--h4">${strategyMessage}</h4>               
            <ul class="product-list product-pagination-container product-list--topsellers">
                ${categoryRecsItemTemplate(items)}
            </ul>
        </div>
    `;
}

const categoryRecsRecentProductsTemplate = (items, strategyMessage) => {
    return `
        <div class="container--recentproducts">
            <h4 class="topsellers--h4">${strategyMessage}</h4>               
            <ul class="product-list product-pagination-container product-list--topsellers product-list--recentproducts">
                ${categoryRecsItemTemplate(items)}
            </ul>
        </div>
    `;
}

const categoryRecsItemTemplate = (products) => {
    let html = "",
        i = 0;
   
    for (let product of products) {
        if (i == 6) break;

        let idArray = product.id.split("-"),
            articleId = idArray[0],
            colorId = idArray[1],
            productLink = product.attributes.link_url.toString().replace("nelly.com", window.location.host);

        let categoryGroup = product.categories.filter(({ id }) => id.includes("nelly_group")),
            categoryStyle = product.categories.filter(({ id }) => id.includes("nelly_style")),
            categoryID = "",
            categoryName = "";

        if (Array.isArray(categoryStyle) && categoryStyle.length > 0) {
            categoryID = categoryStyle[0].id;
            categoryName = categoryStyle[0].name;
        } else if (Array.isArray(categoryGroup) && categoryGroup.length > 0) {
            categoryID = categoryGroup[0].id;
            categoryName = categoryGroup[0].name;
        }

        html += `
            <li class="js-rr-recs-item GA_ProductData" data-shortsku="${product.id}" data-articlenr="${articleId}" data-colorid="${colorId}" data-brand="${product.brand}" data-name="${product.name}" data-price="" data-ordprice="" data-sessioncode="" data-position="0" data-category="${categoryName}" data-categoryid="${categoryID}">
                <div class="in-yo-face">                   
                    <a href="${productLink}" class="GA_Product js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}">
                        <div class="slick-images" data-url="${window.nellyRR.imageListUrl}" data-id="${product.altImageId}">
                            <div class="slick-image" index="0">
                                <picture>
                                    <source type="image/webp" data-srcset="${imageSrcSet(product.imageURL, true)}" />                                   
                                    <img width="177" height="236" decoding="async" class="lazyload img-responsive" alt="${product.name}" data-sizes="auto" 
                                            src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 177 236'%2F%3E"
                                            data-srcset="${imageSrcSet(product.imageURL)}">
                                </picture>
                            </div>
                        </div>
                    </a>
                    <span class="quickbuy">
                        <a href="#" class="hide-quickbuy-mb js-quickbuy js-rr-product-tracking" data-articlenr="${articleId}" data-colorid="${colorId}" data-trackingUrl="${product.clickTrackingURL}">${ window.aliasTexts.quickViewText}</a>
                    </span>
                    <a href="#" class="btn-quickadd btn-pink js-quickadd js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-categoryid="${categoryID}">
                        <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>
                    </a> 
                    ${soldOutTemplate(articleId, colorId)}
                </div>
                <div class="product-feature">
                    <a href="${productLink}" class="GA_Product js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-name="${product.name}" data-brand="${product.brand}" data-price="" data-category="">
                        <span class="title">${product.name}</span>
                        <span class="brand">${product.brand}</span>
                    </a>
                    <span class="overview-price js-rr-price-container">
                        <span style="visibility:hidden;">${product.priceCents}</span>
                    </span>

                    ${window.nellyRR.showInspirationlist == "true" ?
                    `
                        <button class="btn-like js-fav">
                            <i class="icon heart-like"                               
                                data-articlenr="${articleId}"
                                data-colorid="${colorId}"
                                data-isloggedin="${window.nellyRR.isLoggedIn}"></i>
                        </button>
                    ` : ""}

                    ${(window.nellyRR.showWishlistFunctions == "true" && window.nellyRR.isLoggedIn == "true") ?
                    `
                        <button class="btn-wish js-wish">
                            <i class="icon heart-like wish-like"
                                data-articlenr="${articleId}"
                                data-colorid="${colorId}"
                                data-isloggedin="${window.nellyRR.isLoggedIn}"></i>
                        </button>
                    ` : ""}

                </div>
            </li>
            `
        i++;
    };
    return html;
}

const categoryRecsPriceTemplate = (product) => {
    let html = "";
    html += `     
            ${product.IsDiscount ?
            `
                <span class="overview-sale">${product.Price}</span>
                <span class="overview-original">${product.OrdinaryPrice}</span>
                <span class="overview-percentage">${product.Discount}</span>
            ` :
            `
                <span class="overview-price">${product.Price}</span>
            `
        }
        `
    return html;
}

export {
    categoryRecs1Template,
    categoryRecsRecentProductsTemplate,
    categoryRecsPriceTemplate
}