import { addProductToCart, updateCartAndFavourites, isDev } from '../../Components/helper';
import { noSizesList, sizesList, gaProductDataTag } from './quickadd-templates';
import { getMonitorSize } from '../../Components/monitor-size';
import { addToCart } from '../../Components/RichRelevance/richrelevance';

const cachedValues = {};

const init = () => {
    let quickAddButtons = [];
    quickAddButtons = document.querySelectorAll('.js-quickadd');

    //if there is no quickadd-slider, remove buttons and exit
    if (!document.getElementById('quickadd')) { quickAddButtons.forEach(button => { button.style.display = "none"; }); return; }

    //console.log('quickadd.js');

    cachedValues.quickAdd = document.getElementById('quickadd');
    cachedValues.quickAddData = document.getElementById('quickadd_data');
    cachedValues.quickAddOverlay = document.getElementById('quickadd_overlay');
    cachedValues.quickAddContent = document.getElementById('quickadd_content');
    cachedValues.quickAddSizes = document.getElementById('quickadd_sizes');
    cachedValues.mobileMiniCartIcon = document.querySelector('#ShoppingBagToggleMobile>.fubar-bag');
    cachedValues.sizeDataUrl = document.getElementById('getQuickBuySizes').value;
    cachedValues.pageType = document.getElementById('pageType').value;
        
    //add click listeners
    document.addEventListener("click", (e) => {
        onShowSizeSelector(e), onSelectSize(e), onCloseQuickAdd(e);
    });
};

const onShowSizeSelector = (e) => {
    var ele = e.target.closest('.js-quickadd');
    if (ele) {

        ele.classList.add('sizes-loading');

        const gaDataEle = ele.closest('.GA_ProductData');

        let product = {
            articleNr: ele.dataset.articlenr,
            colorId: ele.dataset.colorid,
            sizeId: null,
            categoryId: ele.dataset.categoryid,
            name: gaDataEle.dataset.name || "",
            brand: gaDataEle.dataset.brand || "",
            category: gaDataEle.dataset.category || "",
            price: gaDataEle.dataset.price || "",
            ordPrice: gaDataEle.dataset.ordprice || "",
            position: gaDataEle.dataset.position || "",
            ticket: gaDataEle.dataset.ticket || "",
            sessionCode: gaDataEle.dataset.sessioncode || ""
        };
        if (isDev) { console.log(product); }

        const activeProduct = `${product.articleNr}-${product.colorId}`;

        if (cachedValues.quickAdd.dataset.activeproduct === activeProduct) { //the list already contains these sizes
            cachedValues.quickAdd.classList.add('show-sizes'); //opens modal for sizes
            document.body.classList.add('noscroll');
            return;
        }

        //add GA data tag
        cachedValues.quickAddData.innerHTML = gaProductDataTag(product);

        //in PDP we can tell whether product is onesize or not. If so, don't request sizedata
        if (ele.classList.contains('is-onesize')) {
            product.sizeId = "008";
            if (cachedValues.pageType !== "checkout") {
                addProductAndUpdateCart(product);
            } else {
                addProductFromCartUpsell(product, ele); //method from legacy JS - includes jQuery
            }
            clearLoadingSizesSpinners();
            return;
        }

        const getSizesUrl = `${cachedValues.sizeDataUrl}?articleNr=${product.articleNr}&colorId=${product.colorId}`;
        fetch(getSizesUrl)
            .then(response => {
                response.json()
                    .then(data => {

                        if (data.productQuickBuySizeModel
                            && data.productQuickBuySizeModel.Sizes.length === 1
                            && data.productQuickBuySizeModel.Sizes[0].Id === "008") {
                            try {
                                //directly add if onesize
                                product.sizeId = "008";
                                if (cachedValues.pageType !== "checkout") {
                                    addProductAndUpdateCart(product);
                                } else {
                                    addProductFromCartUpsell(product, ele); //method from legacy JS - includes jQuery
                                }
                                ele.classList.remove('sizes-loading');
                            } catch (e) {
                                cachedValues.quickAddSizes.innerHTML = noSizesList();
                                console.log(e);
                            }
                            clearLoadingSizesSpinners();
                            return;
                        }

                        //render size list
                        let sizeModel = data.productQuickBuySizeModel;
                        sizeModel.CategoryId = product.categoryId;

                        if (data.productQuickBuySizeModel.Sizes.length > 0) {
                            cachedValues.quickAddSizes.innerHTML = sizesList(sizeModel);
                        } else {
                            cachedValues.quickAddSizes.innerHTML = noSizesList();
                        }

                        cachedValues.quickAdd.classList.remove('show-added-to-cart');
                        cachedValues.quickAdd.classList.add('show-sizes'); //opens the size selectormodal for mobile view
                        cachedValues.quickAdd.classList.add('js-quickbuy')
                        clearLoadingSizesSpinners();
                        document.body.classList.add('noscroll');
                        cachedValues.quickAdd.dataset.activeproduct = activeProduct;
                        if ('scrollBehavior' in document.documentElement.style) {
                            cachedValues.quickAddSizes.scrollTo(0, 0);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        clearLoadingSizesSpinners();
                    });
            });
    }
};

const showAddedToCart = (product) => {
    cachedValues.quickAdd.classList.add('show-added-to-cart');
    document.body.classList.add('noscroll');
    addToCart("add_to_cart_page.nelly_recs_1", product);
};

const onSelectSize = (e) => {
    var ele = e.target.closest('.quickadd-size');
    if (ele) {
        const productData = document.getElementById('GA_productQuickAddDetail');
        const product = {
            articleNr: ele.dataset.articlenr,
            colorId: ele.dataset.colorid,
            sizeId: ele.dataset.sizeid,
            sizeName: ele.dataset.sizename,
            categoryId: ele.dataset.categoryid,
            name: productData.dataset.name
        };
        const quickAddBtn = document.querySelector(`.js-quickadd[data-articlenr="${product.articleNr}"][data-colorid="${product.colorId}"]`);

        if (ele.classList.contains('size-soldout')) {
            if (isDev) { console.log("size soldout"); }
            const sku = `${product.articleNr}-${product.colorId}`;
            getMonitorSize(sku, product.sizeId);
            return;
        }

        if (cachedValues.pageType !== "checkout") {
            addProductAndUpdateCart(product);
        } else {
            addProductFromCartUpsell(product, quickAddBtn); //method from legacy JS - includes jQuery
        }
    }
};

const addProductAndUpdateCart = (product) => {

    const onSuccess = (data) => {
        if (data && data.Data) {
            if (data.Data.OutOfStock == true) {
                if (isDev) console.log("out of stock");
                const quickAddContainer = document.querySelector(`.quickadd-size[data-articlenr="${product.articleNr}"][data-colorid="${product.colorId}"][data-sizeid="${product.sizeId}"]`);
                if (quickAddContainer) {
                    quickAddContainer.classList.add("size-soldout");
                    const quickAddBtn = quickAddContainer.querySelector(".size-btn");
                    if (quickAddBtn) {
                        quickAddBtn.innerHTML = window.aliasTexts.soldOutSorryText;
                    }                       
                }                        
            } else {
                updateCartAndFavourites((data) => {
                    if (isDev) { console.log("added to cart"); }
                    GA_ProductAddToCartQuickAdd(data);
                    closeSizeSelector();
                    showAddedToCart(product);
                });
            }
        } else {
            if (isDev) console.log("Data empty");
        }
    }

    const onError = (error) => {
        if (error) {
            if (isDev) {
                console.log("failed to add to cart");
                console.error(error);
            } 
            cachedValues.quickAddSizes.innerHTML = noSizesList();

            //onesize failed to add
            if (product.sizeId === "008" && !cachedValues.quickAdd.classList.contains('show-sizes')) {
                cachedValues.quickAdd.classList.add('show-sizes');
                document.body.classList.add('noscroll');
            }
        }
    }

    addProductToCart(product, onSuccess, onError);
};

const closeAddedToCart = () => {
    cachedValues.quickAdd.classList.remove('show-added-to-cart');
    document.body.classList.remove('noscroll');
    clearLoadingSizesSpinners();
    const sellSlider = document.querySelector('#added_upsell .hold-sell-slideshow');
    if (sellSlider) {
        let emptyContainerTimeout = setTimeout(() => {
            sellSlider.innerHTML = "";
            clearTimeout(emptyContainerTimeout);
        }, 200);
    }
};

const closeSizeSelector = () => {
    cachedValues.quickAdd.classList.remove('show-sizes');
    document.body.classList.remove('noscroll');
    clearLoadingSizesSpinners();
};

const onCloseQuickAdd = (e) => {
    e.stopPropagation()
    const closeX = e.target.closest('#quickadd_close');
    const btnContinue = e.target.closest('#added_cta_continue');
    const quickAddOverlay = e.target.closest('#quickadd_overlay');
    if (closeX || quickAddOverlay || btnContinue) {
        closeAddedToCart();
        closeSizeSelector();
    }
};

const clearLoadingSizesSpinners = (ele = null) => {
    if (ele) { ele.classList.remove('sizes-loading'); return; }
    const spinners = document.querySelectorAll('.sizes-loading');
    if (spinners.length > 0) { spinners.forEach((spinner) => { spinner.classList.remove('sizes-loading'); }); }
};

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}