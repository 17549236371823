const init = () => {
    // Events
    document.querySelectorAll(".js-filter-button").forEach(elem => elem.addEventListener("click", onFilterButtonClick));
};

const onFilterButtonClick = (e) => {
    const element = e.currentTarget;

    document.querySelectorAll(".js-filter-button").forEach(elem => elem.classList.remove("filter-btn-active"));
    element.classList.add("filter-btn-active");

    var poWrappers = document.getElementsByClassName("product-overview-wrapper");

    if (poWrappers.length > 0) {
        window.scrollTo({
            top: poWrappers[0].offsetTop - 100,
            behavior: 'smooth'
        });
    }

    const params = {
        campaign: element.getAttribute("data-campaign") !== '' ? JSON.parse(element.getAttribute("data-campaign")) : '',
        groups: element.getAttribute("data-groups") !== '' ? JSON.parse(element.getAttribute("data-groups")) : '',
        brands: element.getAttribute("data-brands") !== '' ? JSON.parse(element.getAttribute("data-brands")) : '',
        colors: element.getAttribute("data-colors") !== '' ? JSON.parse(element.getAttribute("data-colors")) : '',
        sizes: element.getAttribute("data-sizes") !== '' ? JSON.parse(element.getAttribute("data-sizes")) : '',
        prices: [],
        styles: element.getAttribute("data-styles") !== '' ? JSON.parse(element.getAttribute("data-styles")) : ''
    };

    if (element.getAttribute("data-currency") !== '' && element.getAttribute("data-priceFrom") !== '' && element.getAttribute("data-priceTo") !== '') {
        // Create new nested array
        // Set currency as first element in the array since otherwise it will never match the critera of being the same currency symbol in the filter check
        const priceArray = [
            JSON.parse(element.getAttribute("data-currency")),
            JSON.parse(element.getAttribute("data-priceFrom")),
            JSON.parse(element.getAttribute("data-priceTo"))
        ];
        params.prices.push(priceArray);
    }

    checkboxFilter(params);
};

// Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}