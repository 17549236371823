import throttle from 'lodash.throttle';
import { loadScript } from './helper';

let departmentName = 'SE';
let locale = 'en-US';
let chatLabelTitle = 'Chat with us';
let preChatLabelGreetingTitle = 'Please fill out the form below to chat with us';
let labelOffline = 'Help';
let conciergeTitle = 'Chat support';
let zendeskChatColorTheme = '#FF9393';
let zendeskChatCategory = "";
let zendeskChatCategoryId = "";
let zendeskChatMessageId = "";
let isChatClosedThisSession = window.sessionStorage.getItem('isChatClosedByUser');
let isChatClosedByUser = (isChatClosedThisSession === 'true');
let isZendeskOnline = false;

const nellyChannelId = $('#nelly_channelId').val();
const nellyLanguageId = $('#nelly_languageId').val();

// Set department name in this variable based on country/site. The availble department names are SE, DK and NO (case sensitive).
const setChannelSpecificData = () => {
    switch (parseInt(nellyChannelId)) {
        case 1:
            departmentName = 'Chat - Sweden';
            locale = 'sv';
            break;
        case 2:
            departmentName = 'Chat - Denmark';
            locale = 'da';
            break;
        case 3:
            departmentName = 'Chat - Norway';
            locale = 'no';
            break;
        default:
            departmentName = 'Chat - Sweden';
            locale = 'sv';
            break;
    }

    chatLabelTitle = $("#zendeskChatLabelTitle").val();
    preChatLabelGreetingTitle = $("#zendeskPreChatLabelGreetingTitle").val();
    labelOffline = $("#zendeskLabelOffline").val();
    conciergeTitle = $("#zendeskConciergeTitle").val();
    zendeskChatColorTheme = $("#zendeskChatColorTheme").val();
};

// Checks if the specified department is online or offline. If offline, the chat button is hidden, 
// otherwise it will show the chat button.
const setDepartmentStatus = () => {
    zE('webWidget:on', 'chat:departmentStatus', function (dept) {
        console.log("dept.name: " + dept.name + " dept.status: " + dept.status);
        if (dept.name !== departmentName)
            return;

        var isChatting = zE('webWidget:get', 'chat:isChatting');
        if (isChatting)
            return;

        if (dept.name === departmentName) {
            var nlyChatClosed = document.querySelector("#nlyChatClosed");
            var nlyChatOpen = document.querySelector("#nlyChatOpen");

            if (dept.status === 'offline') {
                if (nlyChatClosed && nlyChatOpen) {
                    nlyChatClosed.style.display = "block";
                    nlyChatOpen.style.display = "";
                }
                isZendeskOnline = false;
                hideWidget();
            } else {
                if (nlyChatClosed && nlyChatOpen) {
                    nlyChatClosed.style.display = "";
                    nlyChatOpen.style.display = "block";
                }
                isZendeskOnline = true;
                showWidget();
            }
        }
    });
};

//Get sibling div (of the textarea) to append the category dropdown
const getSiblingDivForCategoryDropdown = () => {

    const textareaElement = document.getElementById('webWidget').contentDocument.querySelector('textarea');
    if (textareaElement && textareaElement.parentElement && textareaElement.parentElement.parentElement) {
        return textareaElement.parentElement.parentElement;
    }
    return null;
};

//Add a Category List to Prefill Form and Send the selected category as a message 
const setChatCategory = () => {

    if (typeof zE === "undefined") {
        return;
    }

    //Set the Drop Down when opening the chat 
    zE('webWidget:on', 'open', () => {

        var chatCategoryDropDown = document.getElementById('zendeskChatCategoryDropDown').cloneNode(true);
        chatCategoryDropDown.hidden = false;

        setTimeout(() => {
            let siblingForDrp = getSiblingDivForCategoryDropdown();
            if (siblingForDrp) {
                siblingForDrp.insertAdjacentElement("beforebegin", chatCategoryDropDown);
                document.getElementById('webWidget').contentDocument.getElementById('Embed').addEventListener("click", selectZendeskCategory, false);
                document.getElementById('webWidget').contentDocument.getElementById('Embed').addEventListener("change", selectZendeskCategory, false);
                zendeskChatMessageId = document.getElementById('webWidget').contentDocument.getElementsByName('message')[0].id;
            }
        }, 550);

    });

    //Send Selected Category when Chat Starts 
    zE('webWidget:on', 'chat:start', () => {
        var selectedMessage = document.getElementById('zendeskSelectedCategoryText').value;
        if (zendeskChatCategoryId !== "") {
            zE('webWidget', 'chat:send', `${selectedMessage} : ${zendeskChatCategory}`);

            let zendeskCategoryTag = "";
            switch (zendeskChatCategoryId) {
                case "2":
                    zendeskCategoryTag = "category_order_and_payment";
                    break;
                case "4":
                    zendeskCategoryTag = "category_delivery";
                    break;
                case "5":
                    zendeskCategoryTag = "category_product_information";
                    break;
                case "6":
                    zendeskCategoryTag = "category_discount_and_campaign";
                    break;
                case "7":
                    zendeskCategoryTag = "category_complaint";
                    break;
                case "8":
                    zendeskCategoryTag = "category_return";
                    break;
                case "9":
                    zendeskCategoryTag = "category_technical_questions";
                    break;
                case "10":
                    zendeskCategoryTag = "category_refund";
                    break;
                case "11":
                    zendeskCategoryTag = "category_invoice";
                    break;
            }

            zE('webWidget', 'chat:addTags', zendeskCategoryTag);

        }
    });


};

const selectZendeskCategory = (e) => {

    if (e.target.id == "zendeskCategoryId") {
        zendeskChatCategory = document.getElementById('zendeskCategoryId').options[e.target.selectedIndex]?.text;
        zendeskChatCategoryId = document.getElementById('zendeskCategoryId').options[e.target.selectedIndex]?.value;

        if (zendeskChatCategoryId > 0) {
            removeCategoryDropdownValidation();
        }
        else {
            setCategoryDropdownValidation();
        }
    }

    if (e.target.id == zendeskChatMessageId) {

        if (!zendeskChatCategoryId > 0) {
            setCategoryDropdownValidation();
        }
    }
};

const setCategoryDropdownValidation = () => {
    document.getElementById('webWidget').contentDocument.getElementById('zendeskCategoryIdValidationMessage').hidden = false;
    document.getElementById('webWidget').contentDocument.getElementById(zendeskChatMessageId).disabled = true;
};

const removeCategoryDropdownValidation = () => {
    document.getElementById('webWidget').contentDocument.getElementById('zendeskCategoryIdValidationMessage').hidden = true;
    document.getElementById('webWidget').contentDocument.getElementById(zendeskChatMessageId).disabled = false;
};

const scrollPositionForChatVisibility = () => {
        
        if (window.innerWidth >= 768) {
            return;
        }

        var scrollTargetElement = document.querySelector(".pdp-bottom-container");

        if (scrollTargetElement) {
            function onScroll() {
                var rect = scrollTargetElement.getBoundingClientRect();
                var windowTopPos = window.scrollY;
                var windowBottomPos = window.innerHeight + windowTopPos;
                var scrollTargetElementOffsetTop = rect.top + window.scrollY;

                if (windowBottomPos > scrollTargetElementOffsetTop - 125) {
                    showWidget();
                }
                else {
                    hideWidget();
                }
            }
            window.addEventListener("scroll", throttle(onScroll, 50), false);
        }
};

const hideWidget = () => {
    //zE('webWidget', 'hide');
    $('#customZendeskLauncher').hide();
};

const showWidget = () => {
    if (isZendeskOnline && !isChatClosedByUser) {
        $('#customZendeskLauncher').show();
    }
};

const checkDepartmentStatus = () => {
    if (typeof zE === "undefined") {
        return;
    }

    console.log("Checking Zendesk Chat department status");

    if (typeof zE === "undefined")
        return;

    var isChatting = zE('webWidget:get', 'chat:isChatting');
    if (isChatting)
        return;

    if (isChatClosed()) {
        isChatClosedByUser = true;
        return;
    } else {
        isChatClosedByUser = false;
    }

    try {
        const departments = zE('webWidget:get', 'chat:departments');
        if (departments) {
            departments.forEach(department => {
                if (department.name === departmentName) {
                    var nlyChatClosed = document.querySelector("#nlyChatClosed");
                    var nlyChatOpen = document.querySelector("#nlyChatOpen");

                    if (department.status === 'offline') {
                        if (nlyChatClosed && nlyChatOpen) {
                            nlyChatClosed.style.display = "block";
                            nlyChatOpen.style.display = "";
                        }
                        isZendeskOnline = false;
                        hideWidget();
                    } else {
                        if (nlyChatClosed && nlyChatOpen) {
                            nlyChatClosed.style.display = "";
                            nlyChatOpen.style.display = "block";
                        }
                        isZendeskOnline = true;
                        showWidget();
                    }
                }
            });
        }
    } catch (e) {
        return;
    }
};

const onChatOpenClick = (event) => {
    //document.getElementById('launcher').contentWindow.document.getElementById('Embed').getElementsByTagName('button')[0].click();
    onClickCustomLauncher(event);
};

const isChatClosed = () => {
    let isChatClosed = window.sessionStorage.getItem('isChatClosedByUser') ? window.sessionStorage.getItem('isChatClosedByUser') === 'true' : false;
    return isChatClosed;
}

const onClickCustomLauncher = (event) => {
    event.stopPropagation();
    zE('webWidget', 'show');
    zE('webWidget', 'open');
    document.querySelector('#customZendeskLauncher').style.opacity = 0;
    return false;
};

const onClickCustomLauncherClose = (event) => {
    event.stopPropagation();
    hideWidget();
    window.sessionStorage.setItem('isChatClosedByUser', 'true');
    isChatClosedByUser = true;
    return false;
};

const loadZendeskSettings = () => {
    console.log('Loading Zendesk settings');
    setChannelSpecificData();

    // WebWidget settings.
    window.zESettings = {
        webWidget: {
            // Theme Colors
            color: {
                theme: zendeskChatColorTheme,
            },
            // Offset from Window.
            offset: {
                horizontal: '50px',
                vertical: '50px',
                mobile: {
                    horizontal: '5px',
                    vertical: '5px',
                },
            },
            // Launcher Settings (Button)
            launcher: {
                // Label if only chat is enabled or if user started a chat and minimized window.
                chatLabel: {
                    'en-US': 'Chat',
                    sv: 'Chatt',
                    da: 'Chat',
                    no: 'Chat',
                    fi: 'Chatti',
                    '*': 'Chat',
                },
                // Label is only visible if a Widget Setting that is not chat is enabled. This will (only) overwrite chatLabel if true.
                label: {
                    '*': labelOffline,
                },
                // Decides if the mobile version of the button should show the Label (optional).
                mobile: {
                    labelVisible: false,
                },
            },
            // Chat Settings
            chat: {
                //Hides chat offline form when the account status is offline.
                hideWhenOffline: true,
                // Replaces the default title string (in top of chat window) with a custom string.
                title: {
                    '*': chatLabelTitle,
                },
                prechatForm: {
                    // Message shown in the prechat form (optional).
                    greeting: {
                        '*': preChatLabelGreetingTitle,
                    },
                },
                departments: {
                    // Set default department.
                    select: departmentName,
                    // Set enabled departments (If not empty: user will see the select department selector).
                    enabled: ['']
                },
                // Concierge is shown before an agent joins the chat.
                concierge: {
                    avatarPath: 'https://media.nelly.com/i/nlyscandinavia/nelly_small_n_logo?w=200',
                    name: 'Nelly',
                    title: { '*': conciergeTitle },
                },
            },
        },
    }

    //zendeskTokenUrl will exist (in _ChatButton.cshtml) only if authentication feature is enabled
    //If user is not logged in, we should not execute the JWT part, otherwise non-logged users cannot chat.
    const url = $("#zendeskTokenUrl").data("url");
    const isLoggedIn = $('#isLoggedIn').length === 1 ? $('#isLoggedIn').val() === '1' : false;
    if (url && $.trim(url) !== '' && isLoggedIn) {
        window.zESettings.webWidget.authenticate = {
            chat: {
                jwtFn: function (callback) {
                    // Fetch your jwt token and then call our supplied callback below.
                    fetch($.trim(url)).then(function (res) {
                        res.json().then(function (jwt) {
                            if (jwt.success) {
                                callback(jwt.token);
                            }
                        });
                    });
                }
            }
        };
    }
};

const init = () => {
    console.log("Init Zendeks Chat");

    let openChat = document.querySelector(".js-open-chat");
    if (openChat) {
        openChat.addEventListener("click", onChatOpenClick);
    }

    let customZendeskLauncher = document.querySelector("#customZendeskLauncher");
    if (customZendeskLauncher) {
        let btnChat = customZendeskLauncher.querySelector('button.btnChat');
        btnChat.addEventListener("click", onClickCustomLauncher);

        let textElement = btnChat.querySelector('span.label');
        if (textElement) {
            switch (locale) {
                case 'fi':
                    textElement.innerHTML = 'Chatti';
                    break;
                case 'da':
                    textElement.innerHTML = 'Chat';
                    break;
                case 'no':
                    textElement.innerHTML = 'Chat';
                    break;
                default:
                    textElement.innerHTML = 'Chatt';
                    break;
            }
        }

        //mobile button events
        let btnChatMobile = customZendeskLauncher.querySelector('button.btnChatMobile');
        btnChatMobile.addEventListener("click", onClickCustomLauncher);

        let btnChatMobileClose = customZendeskLauncher.querySelector('button.btnChatMobileClose');
        btnChatMobileClose.addEventListener("click", onClickCustomLauncherClose);

        scrollPositionForChatVisibility();
    }

    zE('webWidget', 'hide');

    zE('webWidget:on', 'close', () => {
        zendeskChatCategory = "";
        zE('webWidget', 'hide');
        document.querySelector('#customZendeskLauncher').style.opacity = 1;
    });

    zE('webWidget', 'updateSettings', {
        webWidget:
        {
            position: 'right',
            color: { theme: zendeskChatColorTheme }
        }
    });

    // Set Locale (en-US, sv, da, no, fi).
    zE('webWidget', 'setLocale', locale);
    // Set department status
    setDepartmentStatus();
    setChatCategory();
};

const refreshUserSessionChatClosed = () => {
        window.sessionStorage.setItem('isChatClosedByUser', 'false');
        console.log('running interval CHECK');
        isChatClosedByUser = false;
        checkDepartmentStatus(); //this will show the widget if zendesk is available.
}

const loadZendeskWidget = () => {
    setTimeout(() => {
        loadScript("https://static.zdassets.com/ekr/snippet.js?key=c98b27b8-3b22-4345-9f0b-bc7f2fa20bb0", init, true, "ze-snippet");
    }, 3000)
};

//disable zendesk if ebbot is available
if (!window.nellyEBBOT || window.nellyEBBOT.ebbotEnabled != "true") {
    // Public methods //
    window.setDepartmentStatus = setDepartmentStatus;
    window.zendeskInit = init;
    window.loadZendeskSettings = loadZendeskSettings;
    window.checkDepartmentStatus = checkDepartmentStatus;
    window.setInterval(checkDepartmentStatus, 300000);
    window.setInterval(refreshUserSessionChatClosed, 300000);

    // End Public Methods //

    if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
        loadZendeskWidget();
    } else {
        document.addEventListener("DOMContentLoaded", loadZendeskWidget);
    }
}
else {
    var nlyChatClosed = document.querySelector("#nlyChatClosed");
    var nlyChatOpen = document.querySelector("#nlyChatOpen");
    if (nlyChatClosed && nlyChatOpen) {
        nlyChatClosed.style.display = "";
        nlyChatOpen.style.display = "block";
    }
}

