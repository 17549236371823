const soldOutTemplate = (articleId, colorId) => {
    return `
        <a href="javascript:;" class="monitor-product-btn js-monitor-product" data-articlenr="${articleId}" data-colorid="${colorId}">
            <i class="icon icon-monitor-product"></i>
        </a>
        <div class="product-coming-soon-label">${window.aliasTexts.comingSoonText}</div>
        <div class="product-soldout-label">
            <span>${window.aliasTexts.soldOutText}</span>
        </div>
    `
};

export {
    soldOutTemplate
}