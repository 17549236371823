import { isDev, isSendBeaconSupported, browserType } from '../helper';
import { rrLoaded, hasUserAcceptedPersonalization } from './rr-helpers';

window.nellyRR = window.nellyRR || {};
window.nellyRR.trackingLink = null;

const sendTracking = function (url, callback) {
    // GDPR - Make sure that user has accepted personalization before tracking
    if (rrLoaded && hasUserAcceptedPersonalization()) {
        fetch(url)
        .then(response => {
            // Always run callback if it's defined
            if (callback) callback(response);
            if (response.ok) {
                if (isDev) console.log("Tracking OK"); 
            }                    
        })
        .catch(error => console.error('Error:', error));
    }
    else {
        if (callback)
            callback();
    }
};

const trackClickedProduct = () => {
    if (hasUserAcceptedPersonalization()) {
        var url = window.nellyRR.trackingLink;
        if (window.nellyRR.trackingLink != null) {
            if (isSendBeaconSupported()) {
                navigator.sendBeacon(url);
            } else {
                var options = {
                    url: url,
                    async: false,
                    timeout: 2000
                };
                NellyPost(options);
            }
        }
    }
};

const productTracking = function (e) {
  
    let rrProduct = e.target.closest(".js-rr-product-tracking"),
        href = rrProduct.getAttribute("href"),
        target = rrProduct.getAttribute("target"),
        rrTrackingUrl = rrProduct.getAttribute("data-trackingUrl"),
        isFlickity = rrProduct.closest(".flickity-slider"),
        clickType = (typeof e.which != "undefined") ? e.which : e.button;

    // Prevent double tracking events
    if ((!isFlickity && e.type == "click") || isFlickity && ((clickType == 2 || clickType == 3) && e.type == "click"))
        return false;

    if (rrTrackingUrl) {
        // Only need to set URL for tracking later if it's going to be a page reload
        if (href != "#" && href != "javascript:;" && !target) {
            // Save trackingUrl for unload-event if it is a normal click (not trying to open in new tab)
            if (clickType == 1 && !e.ctrlKey && !e.metaKey) {
                window.nellyRR.trackingLink = rrTrackingUrl;
            // Otherwise track immediately (page is not being reloaded)
            } else {
                sendTracking(rrTrackingUrl);
            }
        // Track immediately if it's not real links (like quickbuy etc)
        } else {
            sendTracking(rrTrackingUrl);
        }        
    }

};

// public methods
const contentTracking = function (url, callback) {
    sendTracking(url, callback);
};

export {
    contentTracking,
    productTracking,
    trackClickedProduct
}