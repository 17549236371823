import { AmplienceHost } from "./helper.js";

const _pictureArrowsEnabledCookieName = 'picturearrowabtest';

const init = () => {
    const pliContainers = document.querySelectorAll(".pli-container");
    if (pliContainers && pliContainers.length > 0) {
        elementScrolledIntoView(pliContainers, 300, loadMedia);

        if (window.innerWidth > 992 || IsPictureArrowsEnabled()) {
            elementScrolledOutOfView(pliContainers, unloadMedia);
        }
    }
};

const loadMedia = (elem) => {
    if (!elem) {
        return;
    }

    // Only check hasloaded if we have a video element
    const videoElement = elem.querySelector(".pli-video");
    if (videoElement) {
        const hasLoaded = videoElement.getAttribute("data-hasloaded");
        if (parseInt(hasLoaded) === 1) {
            return;
        }
    }

    // Check for coming-soon and soldout products
    const parentParentElement = elem.parentElement.parentElement;
    if (parentParentElement.classList.contains("product-comingsoon") || parentParentElement.classList.contains("product-soldout")) {
        return;
    }

    const mediaId = elem.getAttribute("data-mediaid");
    if (!mediaId || mediaId === '') {
        return;
    }

    const slickImages = elem.querySelector(".slick-images");
    const playVideoIcon = elem.querySelector(".pli-play-icon-video");

    const amplienceUrl = `${AmplienceHost.setUrl}${mediaId}.jsonp?protocol=https`;

    fetch(amplienceUrl)
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            return null;
        })
        .then(data => {
            if (data) {
                const video = data.items.find(x => x.type === "video");

                let cookieValue = false;
                const cookie = getCookieValue('plvideoabtest');
                if (cookie) {
                    cookieValue = cookie;
                }

                if (cookieValue === "true" && videoElement && video) {
                    const videoSrcArray = [
                        { src: `${video.src}/webm_product_high`, type: "video/webm", width: 1300, height: 1733 },
                        { src: `${video.src}/mp4_product_high`, type: "video/mp4", width: 1300, height: 1733 },
                        { src: `${video.src}/webm_product_medium`, type: "video/webm", width: 832, height: 1109 },
                        { src: `${video.src}/mp4_product_medium`, type: "video/mp4", width: 832, height: 1109 },
                        { src: `${video.src}/webm_product_low`, type: "video/webm", width: 552, height: 736 },
                        { src: `${video.src}/mp4_product_low`, type: "video/mp4", width: 552, height: 736 }
                    ];

                    videoElement.addEventListener("error", (event) => {
                        const self = event.currentTarget;
                        const hasError = self.getAttribute("data-haserror");
                        if (window.innerWidth > 992 && parseInt(hasError) === 0) {
                            setupSlick(playVideoIcon, videoElement, slickImages, data);
                            self.setAttribute("data-haserror", 1);
                        }
                    }, true);

                    videoSrcArray.forEach(item => {
                        videoElement.appendChild(createVideoFromAmplience(item));
                    });

                    setupVideo(videoElement);
                } else {
                    if (window.innerWidth > 992 || IsPictureArrowsEnabled()) {
                        setupSlick(playVideoIcon, videoElement, slickImages, data);
                    }
                }
            }

        });
};

const getCookieValue = (key) => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith(key));
    if (cookie) {
        return cookie.split('=')[1];
    }
};

const IsPictureArrowsEnabled = () => {
    var cookie = getCookieValue(_pictureArrowsEnabledCookieName);
    return cookie && cookie.toLowerCase() == 'true' && window.innerWidth < 768;
};

const reInitMediaWithVideo = () => {
    if ($.cookie('plvideoabtest') == undefined) {
        unloadSlick();
        $.cookie('plvideoabtest', true, { path: '/' });
        initMedia();
    }

};

const unloadSlick = () => {
    const slickImages = $(".js-product-list").find(".slick-images.loaded");

    $.each(slickImages, function (index, image) {
        let slickJquery = $(image);
        if (slickJquery.hasClass("js-has-video")) {
            slickJquery.removeClass("loaded");
            slickJquery.slick('unslick').hover(null, null);
        }
    });
};

const unloadMedia = (elem) => {
    const video = elem.querySelector(".pli-video");
    if (!video) {
        return;
    }

    video.classList.remove("isplaying");

    if (parseInt(video.getAttribute("data-hasloaded")) === 1) {
        video.setAttribute("data-hasloaded", 0);

        // Removes all child elements
        while (video.lastElementChild) {
            video.removeChild(video.lastElementChild);
        }

        // Now load the video with empty src to force it close connection and gc will take care of the rest
        video.load();

        if (window.innerWidth < 992) {
            const pauseIcon = video.parentElement.querySelector(".pli-pause-icon-video");
            pauseIcon.style.display = "none"
            video.style.display = "none";
        }

        const playIcon = video.parentElement.querySelector(".pli-play-icon-video");
        playIcon.style.display = "block"

        const picture = video.parentElement.querySelector("picture");
        picture.style.display = "block";
    }
};

const setupSlick = (playVideoIcon, videoElement, slickImages, data) => {
    if (slickImages.classList.contains("loaded")) {
        return;
    }
    if (playVideoIcon) {
        playVideoIcon.style.display = "none";
    }

    if (videoElement) {
        videoElement.style.display = "none";
    }

    slickImages.classList.add("loaded");
    $.each(data.items,
        function (index, item) {
            if (item.type === "video")
                slickImages.classList.add("js-has-video");
        });
    
    let imageLayerQuery = slickImages.querySelector('.slick-image')?.getAttribute('data-image-layer-query');
    slickImages.insertAdjacentHTML("beforeend", createHtmlFromAmplience(data, imageLayerQuery));
    const slickJquery = $(slickImages);

    var responsiveSlick = [];
    if (IsPictureArrowsEnabled()) {
        responsiveSlick = [
            {
                breakpoint: 768,
                settings: {
                    appendArrows: slickJquery.closest('.in-yo-face'),
                    prevArrow: '<button type="button" class="slick-custom-next">Next</button>',
                    nextArrow: '<button type="button" class="slick-custom-prev">Previous</button>',
                    arrows: true
                }
            }
        ];
    }

    slickJquery.slick({
        dots: false,
        swipe: false,
        arrows: false,
        autoplaySpeed: 900,
        fade: true,
        waitForAnimate: false,
        responsive: responsiveSlick
    });

    if (window.innerWidth > 992) {
        slickJquery.hover(playSlick, pauseSlick);
    }

};

const setupVideo = (video) => {
    chooseVideoSource(video, window.innerWidth);

    // Start loading the video if we have not loaded it yet
    if (video.readyState === 0 && parseInt(video.getAttribute("data-hasloaded")) === 0) {
        video.load();
        video.setAttribute("data-hasloaded", 1);
    }

    const playVideoIcon = video.parentElement.querySelector(".pli-play-icon-video");
    playVideoIcon.style.display = "block";

    // Pause video in mobile when element is out of view
    if (window.innerWidth < 992) {
        playVideoIcon.addEventListener("click", playVideoMobile);

        const pauseVideoIcon = video.parentElement.querySelector(".pli-pause-icon-video");
        pauseVideoIcon.addEventListener("click", pauseVideoMobile);

        elementScrolledOutOfView(video.parentElement.parentElement.parentElement.parentElement, pauseVideoOnScrollMobile);
    } else {
        $(video.parentElement.parentElement.parentElement.parentElement).hover(playVideo, pauseVideo);
    }
};

const chooseVideoSource = (video, containerWidth) => {
    let videoSources = Array.from(video.children);
    let videoSourcesGroupedByType = videoSources.reduce((groupedByType, source) => {
        //Remove each src attribute to support changing video qualities when resizing
        //source.removeAttribute("src");

        const group = (groupedByType[source.type] || []);
        group.push(source);
        groupedByType[source.type] = group;

        return groupedByType;
    }, {});

    for (var key in videoSourcesGroupedByType) {
        if (videoSourcesGroupedByType.hasOwnProperty(key)) {
            let videosWithType = videoSourcesGroupedByType[key];
            let sortedByWidth = videosWithType.sort((first, second) => parseInt(first.width) - parseInt(second.width));

            let hasUpdatedSource = false;

            for (let i = 0; i < sortedByWidth.length; i++) {
                let source = sortedByWidth[i];
                const parsedWidth = parseInt(source.width);

                if (parsedWidth > containerWidth) {
                    source.setAttribute("src", appendHTTPS(source.src));
                    hasUpdatedSource = true;
                    break;
                }
            }

            //We didn't find a video that was large enough so we'll just use the largest one
            if (!hasUpdatedSource) {
                let largestVideo = sortedByWidth[sortedByWidth.length - 1];
                largestVideo.setAttribute("src", appendHTTPS(largestVideo.src));
            }
        }
    }
};

const appendHTTPS = (src) => {
    let newSrc = src;
    if (!src.includes("protocol=https")) {
        newSrc += "?protocol=https";
    }
    return newSrc;
};

const playSlick = (elem) => {
    const self = $(elem.currentTarget);
    self.addClass("playing");
    self.slickNext();
    self.slickPlay();
};

const pauseSlick = (elem) => {
    const self = $(elem.currentTarget);
    self.removeClass("playing");
    self.slickPause();
    if (self.slickCurrentSlide() !== 0) {
        self.slickGoTo(0);
    }
};

const playVideo = (elem) => {
    const self = elem.currentTarget;
    const video = self.querySelector(".pli-video");

    // Video is already playing, do nothing
    if (video.classList.contains("isplaying")) {
        return;
    }

    video.style.display = "block";
    video.play();
    video.classList.add("isplaying");

    const picture = self.querySelector("picture");
    picture.style.display = "none";
    const playIcon = self.querySelector(".pli-play-icon-video");
    playIcon.style.display = "none"
};

const pauseVideo = (elem) => {
    const self = elem.currentTarget ? elem.currentTarget : elem;

    const playIcon = self.querySelector(".pli-play-icon-video");
    playIcon.style.display = "block"

    const picture = self.querySelector("picture");
    picture.style.display = "block";

    const video = self.querySelector(".pli-video");
    if (video.classList.contains("isplaying")) {
        video.pause();
        video.currentTime = 0;
        video.style.display = "none";
        video.classList.remove("isplaying");
    }
};

const playVideoMobile = (elem) => {
    elem.preventDefault();
    elem.stopPropagation();

    const self = elem.currentTarget;
    const video = self.parentElement.querySelector(".pli-video");
    if (!video) {
        return;
    }

    /// USE THIS IF WE WANT TO PAUSE THE VIDEO WHEN PLAYEDTHROUGH
    // Set loop to false for mobile because they have pause icon and also playthrough if no pause event happened.
    //video.loop = false;

    // Check if registered 'onended' event already to not register multiple
    //if (!video.onended) {
    //    video.onended = (event) => {
    //        const video = event.currentTarget;
    //        video.pause();
    //        video.currentTime = 0;

    //        const picture = video.parentElement.querySelector("picture");
    //        picture.style.display = "block";

    //        video.style.display = "none";

    //        const pauseIcon = video.parentElement.querySelector(".pli-pause-icon-video");
    //        pauseIcon.style.display = "none";

    //        const playIcon = video.parentElement.querySelector(".pli-play-icon-video");
    //        playIcon.style.display = "block";
    //    };
    //}

    video.style.display = "block";
    video.play();
    video.classList.add("isplaying");

    const picture = self.parentElement.querySelector("picture");
    picture.style.display = "none";

    self.style.display = "none"

    const pauseIcon = self.parentElement.querySelector(".pli-pause-icon-video");
    pauseIcon.style.display = "block";
};

const pauseVideoMobile = (elem) => {
    elem.preventDefault();
    elem.stopPropagation();

    pauseAllVideos();
};

const pauseVideoOnScrollMobile = (elem) => {
    unloadMedia(elem);
};

const pauseAllVideos = () => {
    const videos = Array.from(document.querySelectorAll(".pli-video"));
    const videosToPause = videos.filter(video => {
        return video.classList.contains("isplaying");
    });

    if (videosToPause && videosToPause.length > 0) {
        videosToPause.forEach(video => {
            video.pause();
            video.currentTime = 0;
            video.style.display = "none";
            video.classList.remove("isplaying");

            const picture = video.parentElement.querySelector("picture");
            picture.style.display = "block";

            const pauseIcon = video.parentElement.querySelector(".pli-pause-icon-video");
            pauseIcon.style.display = "none";

            const playIcon = video.parentElement.querySelector(".pli-play-icon-video");
            playIcon.style.display = "block";
        });
    }
};

const createVideoFromAmplience = (data) => {
    const videoSrc = document.createElement("source");
    videoSrc.src = data.src; //+ "?protocol=https"
    videoSrc.type = data.type;
    videoSrc.height = data.height;
    videoSrc.width = data.width;

    return videoSrc;
};

const createHtmlFromAmplience = (data, imageLayerQuery) => {
    const template = "<div index='[index]'><picture><source type='image/webp' data-srcset='[url]?$categorypage_LowQ$[layer]&fmt=webp 177w,[url]?$categorypage_XXS$[layer]&fmt=webp 203w,[url]?$categorypage_XS$[layer]&fmt=webp 276w,[url]?$categorypage_S$[layer]&fmt=webp 364w,[url]?$categorypage_M$[layer]&fmt=webp 452w,[url]?$categorypage_L$[layer]&fmt=webp 552w, [url]?$categorypage_XL$[layer]&fmt=webp 728w, [url]?$categorypage_XXL$[layer]&fmt=webp 832w' /><img width='276' height='368' decoding='async' data-sizes='auto' class='lazyload img-responsive' data-srcset='[url]?$categorypage_LowQ$[layer] 177w,[url]?$categorypage_XXS$[layer] 203w,[url]?$categorypage_XS$[layer] 276w,[url]?$categorypage_S$[layer] 364w,[url]?$categorypage_M$[layer] 452w,[url]?$categorypage_L$[layer] 552w, [url]?$categorypage_XL$[layer] 728w, [url]?$categorypage_XXL$[layer] 832w'></picture></div>";
    let html = "";
    data.items.shift();
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].src.indexOf("_40") === -1 && data.items[i].type === "img") {
            html += template.replace("[index]", i).split("[url]").join(data.items[i].src).split("[layer]").join(imageLayerQuery ? `&${imageLayerQuery}` : '');
        }
    }
    return html;
};

const elementScrolledIntoView = (elem, offset, callback) => {
    let targetElement,
        elemOffset = offset || 0;

    // If Element(s)
    if (typeof elem.nodeType !== "undefined" || (typeof elem !== "undefined" && elem.length > 0)) {
        targetElement = elem;
        // If String (class or id)
    } else {
        targetElement = document.querySelector(elem);
    }

    if (targetElement) {
        observeElementIntoView(targetElement, elemOffset, callback);
    }
};

const observeElementIntoView = (elem, offset, callback) => {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (callback) callback(entry.target);
                //observer.unobserve(entry.target);
                //_elementReached(entry.target);
            }
        });
    }, { rootMargin: `0px 0px 0px 0px` });

    // Observe Multiple Elements
    if (typeof elem !== "undefined" && elem.length > 0) {
        elem.forEach(entry => {
            observer.observe(entry);
        });
        // Observe Single Element
    } else {
        observer.observe(elem);
    }
};

const elementScrolledOutOfView = (elem, callback) => {
    let targetElement = null;

    // If Element(s)
    if (typeof elem.nodeType !== "undefined" || (typeof elem !== "undefined" && elem.length > 0)) {
        targetElement = elem;
        // If String (class or id)
    } else {
        targetElement = document.querySelector(elem);
    }

    if (targetElement) {
        observeElementOutOfView(targetElement, callback);
    }
};

const observeElementOutOfView = (elem, callback) => {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                if (callback) {
                    callback(entry.target);
                }

                // Uncommented for now since we dont want to stop observing because its 
                // possible that user play the video again and then are going out of view again, then it wont stop playing the video
                //observer.unobserve(entry.target);
            }
        });
    }, { rootMargin: "0px" });

    // Observe Multiple Elements
    if (typeof elem !== "undefined" && elem.length > 0) {
        elem.forEach(entry => {
            observer.observe(entry);
        });
        // Observe Single Element
    } else {
        observer.observe(elem);
    }
};

window.initMedia = init;
window.reInitMediaWithVideo = reInitMediaWithVideo;

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}