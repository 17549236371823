import { elementScrolledIntoView } from './helper.js';

let footerLogos;

const init = () => {
    let footer = document.querySelector(".footer");
    if (footer) {
        footerLogos = document.querySelectorAll(".footer-logo");
        if (footerLogos.length > 0) {
            elementScrolledIntoView(footer, 300, loadFooterLogos);
        }
    }
};

const loadFooterLogos = () => {
    if (footerLogos) {
        footerLogos.forEach(footerLogo => {
            footerLogo.setAttribute("src", footerLogo.getAttribute("data-src"));
        });
        SVGInject(footerLogos);
    }
};

// Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}