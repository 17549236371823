import { getProductData } from "./helper";

const cachedElements = {};
const cachedData = [];

const init = () => {
    document.addEventListener("click", (e) => {
        onCloseMonitorSize(e), onMonitorProduct(e);
    });
};

const getElementsForCache = () => {
    cachedElements.monitorSize = document.querySelector('#monitor_size');
    cachedElements.monitorSizeForm = document.querySelector('#monitor_size_form_info');
};

const getMonitorSize = (sku, sizeId) => {
    getElementsForCache();
    if (cachedElements.monitorSize && cachedElements.monitorSizeForm) {
        const onSuccess = function (data) {
            if (data) {
                const productData = data[0];
                cachedElements.monitorSizeForm.innerHTML = monitorSizeFormInfo(productData);
                cachedElements.monitorSizeForm.querySelector(".js-monitor-size").addEventListener("click", onMonitorSizeConfirm);
                if (sizeId) {
                    cachedElements.monitorSizeForm.querySelector('#MonitorSize_SizeId option[value="' + sizeId + '"]').selected = true;
                }
                cachedElements.monitorSize.classList.add('show-monitor-size');
                document.body.classList.add('noscroll');
                if (cachedData.filter(p => p.ShortSku == sku).length == 0) {
                    cachedData.push(productData);
                }               
            }
        }
        const cachedSku = cachedData.filter(p => p.ShortSku == sku);
        if (cachedSku.length > 0) {
            onSuccess(cachedSku);
        } else {
            getProductData([sku], ["GetPrice"], onSuccess);    
        } 
    }
};

const onCloseMonitorSize = (e) => {
    const monitorSizeOverlay = e.target.closest('#monitor_size_overlay');
    const monitorSizeClose = e.target.closest('#monitor_size_close');
    const monitorSizeOk = e.target.closest('#monitor_size_ok');
    if (monitorSizeOverlay || monitorSizeClose || monitorSizeOk) {
        e.preventDefault();
        closeMonitorSize();
    }
};

const onMonitorProduct = (e) => {
    const monitorProductButton = e.target.closest('.js-monitor-product');
    if (monitorProductButton) {
        const articleNr = monitorProductButton.getAttribute("data-articlenr");
        const colorId = monitorProductButton.getAttribute("data-colorid");
        const sizeId = monitorProductButton.getAttribute("data-sizeid");
        if (articleNr && colorId) {
            getMonitorSize(`${articleNr}-${colorId}`, sizeId ? sizeId : false);
        }
    }
};

const closeMonitorSize = () => {   
    document.body.classList.remove('noscroll');
    cachedElements.monitorSize.classList.remove('show-monitor-size');
};

const monitorSizeFormInfo = (product) => {
    const languageId = document.getElementById('nelly_languageId') ? document.getElementById('nelly_languageId').value : 1;
    const soldOutSizes = product.SoldOutSizes;
    let html = "";

    if (soldOutSizes.length > 0) {
        const skuArray = product.ShortSku.split("-");
        const articleNr = skuArray[0];
        const colorId = skuArray[1];
        const email = product.Email ? product.Email : "";
        html = `
            <input type="hidden" name="MonitorSize.ArticleId" value="${articleNr}" />
            <input type="hidden" name="MonitorSize.ColorId" value="${colorId}" />            
            <input type="hidden" name="MonitorSize.LanguageId" value="${languageId}" />
            <h4>${window.aliasTexts.monitorSizeText}</h4>

            <div class="monitor-size-product-info-container">
                <div class="monitor-size-product-info-image">
                    <img class="lazyload img-responsive" data-src="${product.MediaUrl}?$categorypage_XS$" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 212 282'%2F%3E" />
                </div>
                <div class="monitor-size-product-info-description product-feature">
                    <span class="monitor-size-name">${product.Name}</span>
                    <span class="monitor-size-brand">${product.BrandName}</span>
                    <span class="monitor-size-color">${product.ColorName}</span>
                    <span class="monitor-size-price">${monitorSizePrice(product)}</span>
                </div>
            </div>
            
            <div class="monitor-size-email">
                <label for="MonitorSize_MonitorEmail">${window.aliasTexts.emailAdressLabelText}</label>
                <input id="monitor_size_email" name="MonitorSize.MonitorEmail" required type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" value="${email}">
                <p class="text-danger small email-validation-message hidden"></p>
            </div>
            <div class="monitor-size-select-and-button">
                <div class="monitor-size-selector">
                    <select id="MonitorSize_SizeId" name="MonitorSize.SizeId" required>
                    ${soldOutSizes.map(size =>
                        `<option value="${size.Id}">
                            ${size.Name}
                        </option>`).join("")}
                    </select>
                </div>
                <div class="monitor-size-send-button">
                    <input value="${window.aliasTexts.letMeKnowText}" type="submit" class="btn btn-pink js-monitor-size" />
                </div> 
            </div>
            `;
    }
    return html;
};

const monitorSizePrice = (product) => {
    let html = "";
    html += `     
            ${product.IsDiscount ?
            `
                <span class="overview-sale">${product.Price}</span>
                <span class="overview-original">${product.OrdinaryPrice}</span>
                <span class="overview-percentage">${product.Discount}</span>
            ` :
            `
                <span class="overview-price">${product.Price}</span>
            `
        }
        `
    return html;
}

const monitorSizeMessage = (data) => {
    let html = "";
    if (data.Status && data.Status == 1) {
        html = `<i class="icon checkmark-pink"></i><h4>${data.Message}</h4>`;
    } else {
        html = `<h4>${data}</h4>`;
    }
    html += `<a href="#" class="btn btn-pink" id="monitor_size_ok">${window.aliasTexts.closeText}</a>`;
    return html;
};

const onMonitorSizeConfirm = (e) => {
    var ele = e.target.closest('.js-monitor-size');
    if (ele) {
        e.preventDefault();
        const monitorSizeForm = ele.closest('form');
        const email = monitorSizeForm.querySelector('#monitor_size_email') ? monitorSizeForm.querySelector('#monitor_size_email') : null;
        if (email.checkValidity()) {

            let formData = new FormData();
            let antiForgeryToken = "";
            for (var i = 0; i < monitorSizeForm.elements.length; i++) {
                var field = monitorSizeForm.elements[i];
                formData.append(field.name, field.value);
                if (field.name === '__RequestVerificationToken') { antiForgeryToken = field.value; }
            }

            const monitorSizeUrl = monitorSizeForm.action;

            fetch(monitorSizeUrl, {
                method: 'post',
                headers: {
                    '__requestverificationtoken': antiForgeryToken
                },
                body: formData
            })
                .then(res => res.json())
                .then(data => {
                    if (data.Status == 1) {
                        cachedElements.monitorSizeForm.innerHTML = monitorSizeMessage(data);
                    } else {
                        cachedElements.monitorSizeForm.innerHTML = monitorSizeMessage(window.aliasTexts.generalErrorText);
                    }
                })
                .catch(error => {
                    cachedElements.monitorSizeForm.innerHTML = monitorSizeMessage(window.aliasTexts.generalErrorText);
                });
        }
        else {
            let validationMessage = email.parentNode.querySelector('.email-validation-message');
            validationMessage.innerHTML = window.aliasTexts.invalidEmailText;
            validationMessage.classList.remove('hidden');
        }
    }
};

//Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}

export {
    getMonitorSize
};