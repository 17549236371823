import { productCardSlider, shopTheLookCta, mainCreditLink, creditSliderTemplate } from './credit-templates';
import { elementScrolledIntoView, paddedSku, getProductDataUrl, getSimilarProductsUrl } from '../../Components/helper';
import { getColorImages } from '../../Components/color-picker.js';

const cachedValues = {};

const init = () => {

    document.addEventListener("click", (e) => {
        showSimilar(e), closeCreditSlider(e), showCredits(e)
    });

    let shoppableElements = [];
    shoppableElements = document.querySelectorAll('[data-creditsref]');

    if (shoppableElements && shoppableElements.length > 0) {
        //add shop the look cta and intersectionobserver
        addShopTheLookCta(shoppableElements);

        //add main credits
        addMainCredits(shoppableElements);
    }
};

const createOverlay = () => {
    if (!document.getElementById('credit_slider')) {
        const overlayHtml = creditSliderTemplate();
        document.querySelector("body").insertAdjacentHTML("beforeend", overlayHtml);
        cachedValues.creditSlider = document.getElementById('credit_slider');
        cachedValues.creditSliderContent = document.getElementById('credit_slider_content');     
        cachedValues.creditSliderProducts = document.getElementById('credit_slider_products');
        cachedValues.creditSliderOverlay = document.getElementById('credit_slider_overlay');
        cachedValues.similarSlider = document.getElementById('similar_slider_content');
        cachedValues.similarSliderProducts = document.getElementById('similar_slider_products');
    }
};

const addShopTheLookCta = (shoppableElements) => {
    shoppableElements.forEach((el) => {
        const ctaText = el.dataset.ctatext || null;
        const ctaNode = htmlToElement(shopTheLookCta(ctaText));
        el.appendChild(ctaNode);

        if (typeof window.IntersectionObserver === "function") {
            let observer = new IntersectionObserver((elements) => {
                elements.forEach(element => {
                    if (!element.target.classList.contains('cta_inview') && element.intersectionRatio !== 0) {
                        element.target.classList.add('cta_inview');
                    } else {
                        element.target.classList.remove('cta_inview');
                    }
                });
            });
            observer.observe(ctaNode);
        } else {
            ctaNode.classList.add('cta_inview');
        }
    });
};

const addMainCredits = (shoppableElements) => {
    shoppableElements.forEach((el) => {
        const mainCredit = el.dataset.maincredit || null;
        if (mainCredit && mainCredit !== "false") {
            const fallbackLimit = el.dataset.fallbacklimit ? parseInt(el.dataset.fallbacklimit) : 3;
            const creditsRef = el.dataset.creditsref;
            const creditArray = creditRefList[creditsRef];
            const creditList = Array.isArray(creditArray) ? creditArray : creditArray.toString().split(',');
            if (creditList.indexOf(mainCredit) > 0) {
                creditList.splice(creditList.indexOf(mainCredit), 1);
                creditList.unshift(mainCredit);
            }
            if (creditList && creditList.length > 0) {
                elementScrolledIntoView(el, 500, () => {
                    tryFetchingCredit(creditList, fallbackLimit, (data) => {
                        el.insertAdjacentHTML('beforeend', mainCreditLink(data));
                        getColorImages();
                    });
                });
            }
        }
    });
};

const tryFetchingCredit = (creditList, retryLimit, callback, iteration = 0) => {
    if (!creditList[iteration]) { console.log("no more products"); return false; }

    let url = getProductDataUrl([creditList[iteration]], ["GetPrice", "GetLink", "GetStyle", "GetColorVariations"]);
    fetch(url)
        .then(res => {
            if (res.status !== 200 && retryLimit >= 0 && creditList[iteration]) {
                return tryFetchingCredit(creditList, --retryLimit, callback, ++iteration);
            }
            return res.json();
        })
        .then(data => {
            if (data && data.length > 0 && data[0].InStock) {
                callback && typeof callback === "function" ? callback(data[0]) : null;
                
                return true;
            } else {
                if (retryLimit >= 0 && creditList[iteration]) {
                    return tryFetchingCredit(creditList, --retryLimit, callback, ++iteration);
                }
            }
        })
    
        .catch(error => {
            console.log(error);
        });
};

//helper function to convert string to element
const htmlToElement = (html) => {
    var div = document.createElement('div');
    html = html.trim(); // Never return a text node of whitespace as the result
    div.innerHTML = html;
    return div.firstChild;
};

const showCredits = (e) => {
    const el = e.target;
    const creditsRefEl = el.closest("[data-creditsref]");

    if (creditsRefEl) {
        let creditList = [];

        if (el.closest('[data-creditsref] a') || creditsRefEl.dataset.creditsref.trim().length < 1) { return; } //clicked a link instead or the data was empty

        createOverlay();

        const creditsRef = creditsRefEl.dataset.creditsref;

        if (cachedValues.creditSlider.dataset.activeref === creditsRef) { //the slider already contains these credits
            cachedValues.creditSlider.classList.add('show-credits');
            document.body.classList.add('noscroll');
            return;
        }

        const creditArray = creditRefList[creditsRef];
        creditList = Array.isArray(creditArray) ? creditArray : creditArray.toString().split(',');

        if (creditList && creditList.length > 0) {

            //fetch product data
            const url = getProductDataUrl(creditList, ["GetPrice", "GetLink", "GetStyle", "GetColorVariations"]);
            fetch(url)
                .then(response => {
                    response.json()
                        .then(data => {
                            const activeProducts = getActiveProducts(data);
                            const soldOutProducts = getSoldOutProducts(data);

                            if (data && activeProducts.length > 0) {
                                cachedValues.creditSliderProducts.innerHTML = productCardSlider(activeProducts);
                                getColorImages();
                            } else {
                                cachedValues.creditSliderProducts.innerHTML = "";
                            }

                            if (soldOutProducts && soldOutProducts.length > 0) {
                                cachedValues.creditSliderProducts.insertAdjacentHTML('beforeend', productCardSlider(soldOutProducts));
                            }

                            cachedValues.creditSlider.classList.add('show-credits');
                            document.body.classList.add('noscroll');
                            cachedValues.creditSlider.dataset.activeref = creditsRef;
                            if ('scrollBehavior' in document.documentElement.style) {
                                cachedValues.creditSliderProducts.scrollTo(0, 0);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                });
        }
    }
};

const showSimilar = (e) => {
    const el = e.target;

    const similarProductsEl = el.closest(".js-show-similar");

    if (similarProductsEl) {

        createOverlay();

        const sku = similarProductsEl.dataset.articlenr + "-" + similarProductsEl.dataset.colorid;
        const styleId = similarProductsEl.dataset.styleid;
        const groupId = similarProductsEl.dataset.groupid;
        const classId = similarProductsEl.dataset.classid;

        // Check if slider is already loaded
        if (cachedValues.similarSlider.dataset.activesimilar === sku) {
            similarProductsEl.classList.add('active-similar');
            cachedValues.creditSlider.classList.add('show-similar');
            return;
        }

        const url = getSimilarProductsUrl(sku, ["GetPrice", "GetLink", "GetStyle"], styleId, groupId, classId);
        fetch(url)
            .then(response => {
                response.json()
                    .then(data => {
                        //render product data
                        const activeProducts = getActiveProducts(data);

                        if (data && activeProducts.length > 0) {
                            cachedValues.similarSliderProducts.innerHTML = productCardSlider(activeProducts);
                            getColorImages();
                        } else {
                            cachedValues.similarSliderProducts.innerHTML = "";
                            return;
                        }

                        similarProductsEl.classList.add('active-similar');
                        cachedValues.creditSlider.classList.add('show-similar');
                        cachedValues.similarSlider.dataset.activesimilar = sku;
                        if ('scrollBehavior' in document.documentElement.style) {
                            cachedValues.similarSliderProducts.scrollTo(0, 0);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
    }
};

const closeCreditSlider = (e) => {
    const el = e.target;
    const sliderIsOpen = el.closest('.show-similar') || el.closest('.show-credits');

    if (!sliderIsOpen) { return; }

    const similarIsOpen = el.closest('.show-similar');
    const similarButton = el.closest('#similar_slider_close');
    const onSimilarOverlay = el.closest('#similar_slider_overlay');
    if (similarIsOpen && (similarButton || onSimilarOverlay)) {
        var activeSimilar = document.querySelector('.active-similar');
        if (activeSimilar) { activeSimilar.classList.remove('active-similar'); }
        cachedValues.creditSlider.classList.remove('show-similar');
        // If creditslider is empty (similarproducts opened without credit products, then close all)
        if (!cachedValues.creditSliderProducts.hasChildNodes()) {
            cachedValues.creditSlider.classList.remove('show-credits');
            document.body.classList.remove('noscroll');
        }
        return;
    }

    const closeBtn = el.closest('#credit_slider_close');
    const overlay = el.closest('#credit_slider') && !el.closest('#credit_slider_content') && !el.closest('#credit_slider_content') && !el.closest('#similar_slider_content');
    const onOverlay = el.closest('#credit_slider_overlay');
    if (closeBtn || overlay || onOverlay) {
        document.body.classList.remove('noscroll');
        cachedValues.creditSlider.classList.remove('show-credits');
        cachedValues.creditSlider.classList.remove('show-similar');
    }
};

const getActiveProducts = (data) => {
    return data.filter((product) => product.Active && (product.CommingSoon || product.InStock));
};

const getSoldOutProducts = (data) => {
    return data.filter((product) => !product.Active || (!product.CommingSoon && !product.InStock));
};

//only returns SKUs for amplience
const getSoldOutProductSkus = (skus, data) => {
    let activeProductSkus = data.map((product) => paddedSku(product.ShortSku));
    return skus.filter(sku => !activeProductSkus.includes(paddedSku(sku)));
};

//run init function when document is ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}