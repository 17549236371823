/* NELLY PRODUCTCARD */
import { paddedSku } from '../../Components/helper';
import { colorPicker } from '../../Components/color-picker';
import { similarProductsTemplate } from '../../Components/similar-products-templates';
const isLoggedIn = document.getElementById("isLoggedIn")?.value.toString() === "1";

const creditSliderTemplate = () => {
    return `<div id="credit_slider">
        <div id="credit_slider_overlay"></div>
        <div id="credit_slider_content" class="slider-content">
            <div id="similar_slider_overlay"></div>
            <div id="credit_slider_close"></div>
            <div id="credit_slider_products" class="slider-products"></div>
        </div>
        <div id="similar_slider_content" class="slider-content">
            <div id="similar_slider_close"></div>
            <div id="similar_slider_products" class="slider-products"></div>
        </div>
    </div>`;
};

const productCard = (data, index = 0) => {
    const articlenr = data.ShortSku.split('-')[0];
    const colorid = data.ShortSku.split('-')[1];
    const price = data.Price ? data.Price : "price error";
    const ordPrice = data.IsDiscount ? data.OrdinaryPrice : price;
    const isActive = data.Active && (data.CommingSoon || data.InStock);
    const comingSoon = !data.InStock && data.CommingSoon;
    const isGiftCard = data.BrandName.trim().toLowerCase() == 'nelly' ? true : false;
    const colorPickerItems = data.ColorPickerItems;
    let productCategoryId = "";
    if (data.StyleId > 0) {
        productCategoryId = "nelly_style_" + data.StyleId;
    }
    else if (data.GroupId > 0) {
        productCategoryId = "nelly_group_" + data.GroupId;
    }
    else if (data.ClassId > 0) {
        productCategoryId = "nelly_class_" + data.ClassId;
    }

    return `
        <div class="GA_ProductData credit-product ${!isActive ? `product-soldout` : ``} ${comingSoon ? `product-comingsoon` : ``}" 
            data-articlenr="${articlenr}" 
            data-colorid="${colorid}" 
            data-category="${data.StyleName}" 
            data-name="${data.Name}" 
            data-brand="${data.BrandName}" 
            data-price="${data.Price}" 
            data-ordprice="${ordPrice}" 
            data-sessioncode="" 
            data-position="${index}">
            <div class="product-image-container">
                <a href="${data.Link}">
                    <picture>
                    <img class="lazyload img-responsive" alt="${data.Name}" data-sizes="auto" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 1449 1844'%2F%3E"
                        data-srcset="${data.MediaUrl}?$categorypage_LowQ$ 177w,
                                    ${data.MediaUrl}?$categorypage_XXS$ 203w,
                                    ${data.MediaUrl}?$categorypage_XS$ 276w,
                                    ${data.MediaUrl}?$categorypage_S$ 364w,
                                    ${data.MediaUrl}?$categorypage_M$ 452w,
                                    ${data.MediaUrl}?$categorypage_L$ 552w,
                                    ${data.MediaUrl}?$categorypage_XL$ 728w,
                                    ${data.MediaUrl}?$categorypage_XXL$ 832w" />
                    </picture>              
                </a>
                ${!data.InStock && data.CommingSoon ?
                `                   
                    <a href="javascript:;" class="monitor-product-btn js-monitor-product" data-articlenr="${articlenr}" data-colorid="${colorid}">
                        <i class="icon icon-monitor-product"></i>
                    </a>
                    <div class="product-coming-soon-label">${window.aliasTexts.comingSoonText}</div>
                `
                : ``}
                ${!isActive ?
                `
                    <div class="product-soldout-label">
                        <span>${window.aliasTexts.soldOutText}</span>     
                        ${similarProductsTemplate(data.ShortSku, data.StyleId, data.GroupId, data.ClassId)}  
                    </div>          
                `
                :
                `<button class="btn-like js-fav">
                        <i class="icon heart-like" data-articlenr="${articlenr}" data-colorid="${colorid}" data-isloggedin="${isLoggedIn}"></i>
                 </button>`
                }
                ${isActive ?
                `<a href="#" class="quickview hide-quickbuy-mb js-quickbuy ${isGiftCard ? `is-gift-card` : ``}" data-articlenr="${articlenr}" data-colorid="${colorid}">
                    ${window.aliasTexts.quickViewText}
                </a>
                <a href="#" class="btn-quickadd btn-pink js-quickadd ${isGiftCard ? `is-gift-card` : ``}" data-articlenr="${articlenr}" data-colorid="${colorid}" data-categoryid="${productCategoryId}">
                    <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>
                </a>`
                : ``}
                </div>
                <div class="product-text-container">
                    <a href="${data.Link}">
                        <span class="brand">${data.BrandName}</span>
                        <span class="name">${data.Name}</span>
                        <span class="overview-price">
                            ${data.IsDiscount ?
                            `<span class="overview-sale">${price}</span>
                                            <span class="overview-original">${ordPrice}</span>
                                            <span class="overview-percentage">${data.Discount}</span>`
                            :
                            `${price}`
                        }
                    </span>
                </a>
                ${colorPickerItems && colorPickerItems.length > 0 ?
                `<div class="color-picker">${colorPicker(colorPickerItems)}</div>`
                : ``}
                
            </div>
        </div>
    `;
};

const productCardSlider = (products) => {
    let html = "";
    products.forEach((credit, index) => {
        html += productCard(credit, index);
    });
    return html;
};

const shopTheLookCta = (text) => {
    return `
        <div class="credit_stl_cta">
            <div class="cta-icon">
                <span class="icon shopping-bag-black"></span>
            </div>
            <div class="cta-text">
                <span>${text ? text.trim() : window.aliasTexts.shopTheLookText}</span>
            </div>
        </div>
    `;
};

const mainCreditLink = (data) => {
    let sku = paddedSku(data.ShortSku);
    const articlenr = sku.split('-')[0];
    const colorid = sku.split('-')[1];
    const price = data.Price ? data.Price : "price error";
    const colorPickerItems = data.ColorPickerItems;
    return `
        <div class="credit_highlight" data-sku="${sku}">
            <a href="${data.Link}" data-articlenr="${articlenr}" data-colorid="${colorid}" class="js-quickbuy">
				<span class="credit-stylename">${data.StyleName}</span>
                ${!data.InStock && data.CommingSoon ?
                `<span class="credit-price">${window.aliasTexts.comingSoonText}</span>`
                :
                `<span class="credit-price">${price}</span>`
                }
            </a>
            ${colorPickerItems && colorPickerItems.length > 0 ?
            `<div class="color-picker">${colorPicker(colorPickerItems)}</div>`
            : ``}
        </div>
    `;
};

const productsNotFound = () => {
    return `
        <div class="credit_stl_notfound">
            <h4>${window.aliasTexts.productsNotLoaded}</h4>
        </div>
    `;
};

export {
    creditSliderTemplate,
    productCardSlider,
    shopTheLookCta,
    productsNotFound,
    mainCreditLink
};