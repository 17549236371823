import { imageSrcSet } from '../rr-helpers.js';
import { soldOutTemplate } from '../../soldout-template.js';

const errorPageRecsTemplate = (items, strategyMessage) => {
  return `
        <div class="product-slider row">
            <div class="section-heading col-xs-12">
                <h2>
                    <span class="text-block text-block--white">${strategyMessage}</span>
                </h2>
            </div>
            <ul class="product-slider__container col-xs-12">
                ${notFoundRecsItemTemplate(items)}
            </ul>
        </div>
    `;
}

const notFoundRecsItemTemplate = (products) => {
  let html = "";
  products.forEach(function (product) {

    let idArray = product.id.split("-"),
        articleId = idArray[0],
        colorId = idArray[1],
        productLink = product.attributes.link_url.toString().replace("nelly.com", window.location.host);

    let categoryGroup = product.categories.filter(({ id }) => id.includes("nelly_group")),
        categoryStyle = product.categories.filter(({ id }) => id.includes("nelly_style")),
        categoryID = "",
        categoryName = "";

    if (Array.isArray(categoryStyle) && categoryStyle.length > 0) {
        categoryID = categoryStyle[0].id;
        categoryName = categoryStyle[0].name;
    } else if (Array.isArray(categoryGroup) && categoryGroup.length > 0) {
        categoryID = categoryGroup[0].id;
        categoryName = categoryGroup[0].name;
    }

    html += `
            <li class="product-slider__item js-rr-recs-item GA_ProductData" data-shortsku="${product.id}" data-articlenr="${articleId}" data-colorid="${colorId}" data-brand="${product.brand}" data-name="${product.name}" data-price="" data-ordprice="" data-category="${categoryName}" data-categoryid="${categoryID}">
                <a href="${productLink}" class="GA_Product js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}">
                    <div class="product-slider__image">
                        <picture>
                            <source type="image/webp" data-srcset="${imageSrcSet(product.imageURL, true)}" />                                   
                            <img class="lazyload img-responsive" alt="${product.name}" data-sizes="auto" 
                                    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 212 282'%2F%3E"
                                    data-srcset="${imageSrcSet(product.imageURL)}">
                        </picture>
                        <a href="#" class="product-slider__quickview hide-quickbuy-mb js-quickbuy js-rr-product-tracking" data-articlenr="${articleId}" data-colorid="${colorId}" data-trackingUrl="${product.clickTrackingURL}">${ window.aliasTexts.quickViewText}</a>
                        <a href="#" class="btn-quickadd btn-pink js-quickadd js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-categoryid="${categoryID}">
                            <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>
                        </a> 
                        ${soldOutTemplate(articleId, colorId)}
                    </div>
                </a>  

                <div class="product-slider__content row">
                    <div class="product-slider__info col-xs-9">
                        <a href="${productLink}" class="js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}">
                            <div class="product-slider__name">
                                <span>${product.name}</span>
                            </div>
                            <div class="product-slider__brand">
                                <span>${product.brand}</span>
                            </div>
                        </a>
                        <div class="product-slider__price js-rr-price-container">
                            <span style="visibility:hidden;">${product.priceCents}</span>                                                                                
                        </div>
                    </div>

                    <div class="product-slider__heart col-xs-3">
                        ${window.nellyRR.showInspirationlist == "true" ?
      `
                            <button class="btn-like js-fav">
                                <i class="icon heart-like"                               
                                    data-articlenr="${articleId}"
                                    data-colorid="${colorId}"
                                    data-isloggedin="${window.nellyRR.isLoggedIn}"></i>
                            </button>
                        ` : ""}

                        ${(window.nellyRR.showWishlistFunctions == "true" && window.nellyRR.isLoggedIn == "true") ?
      `
                            <button class="btn-wish js-wish">
                                <i class="icon heart-like wish-like"
                                    data-articlenr="${articleId}"
                                    data-colorid="${colorId}"
                                    data-isloggedin="${window.nellyRR.isLoggedIn}"></i>
                            </button>
                        ` : ""}
                    </div>                   
                </div>
                <div class="color-picker js-color-picker"></div>
            </li>
        `
  });
  return html;
}

const errorPageRecsPriceTemplate = (product) => {
  let html = "";
  html += `     
            ${product.IsDiscount ?
    `
                <ins>${product.Price}</ins>
                <del>${product.OrdinaryPrice}</del>
                <span class="product-slider__percent-off">${product.Discount}</span>
            ` :
    `
                <span class="overview-price">${product.Price}</span>
            `
  }
        `
  return html;
}

export {
  errorPageRecsTemplate,
  errorPageRecsPriceTemplate
}
