import { isDev } from '../helper';
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const rrLoaded = typeof RR !== "undefined" ? true : false;
const rrRecsEnabled = (typeof window.nellyRR != "undefined" && typeof window.nellyRR.recommendationsEnabled != "undefined" && window.nellyRR.recommendationsEnabled == "true");

const pageType = {
    Home: "home_page",
    Category: "category_page",
    Product: "item_page",
    AddToCart: "add_to_cart_page",
    Search: "search_page",
    Cart: "cart_page",
    PurchaseComplete: "purchase_complete_page",
    Wishlist: "wish_list_page",
    Generic: "generic_page",
    ErrorPage: "error_page"
};

const getPlacementPageType = (placementName) => {
    let placementPageType = placementName.split(".");
    return placementPageType[0];
};

const imageSrcSet = (imageurl, webp) => {

    // Remove pre-defined parameter from RR
    imageurl = imageurl.replace("?$categorypage_M$", "");

    const sizes = [];
    let parameters = "";

    var imageTemplates = [
        { name: "$categorypage_LowQ$", width: "177w" },
        { name: "$categorypage_XXS$", width: "203w" },
        { name: "$categorypage_XS$", width: "276w" },
        { name: "$categorypage_S$", width: "364w" },
        { name: "$categorypage_M$", width: "452w" },
        { name: "$categorypage_L$", width: "552w" },
        { name: "$categorypage_XL$", width: "728w" },
        { name: "$categorypage_XXL$", width: "832w" }
    ];

    if (webp) {
        parameters = "&fmt=webp";
    }

    imageTemplates.forEach(function (template) {
        var url = `${imageurl}?${template.name}${parameters} ${template.width}`;
        sizes.push(url);
    });

    return sizes.toString();
}

const getRrRcs = () => {   
    let rcs = "";

    const cookie = readCookie("rr_rcs");
    if (cookie != null) {
        rcs = cookie;
    }

    return rcs;
}

const formatPrice = (priceCents) => {
    let price = priceCents / 100;
    if (price % 1 == 0) {
        price = parseInt(price, 10);
    } else {
        price = price.toFixed(2);
    }
    return price;
}

// GDPR - if customer has accepted personalization - with fallback on checking serverCookie if Cookiebot somehow fails to load
const hasUserAcceptedPersonalization = () => {
    return (typeof Cookiebot != "undefined" && typeof Cookiebot.consent != "undefined" && Cookiebot.consent.statistics == true) || (window.nellyRR.hasAcceptedPersonalization == "true");
}

export {
    imageSrcSet,
    isChrome,
    getPlacementPageType,
    pageType,
    rrLoaded,
    rrRecsEnabled,
    getRrRcs,
    formatPrice,
    hasUserAcceptedPersonalization
}