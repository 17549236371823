import { elementScrolledIntoView, AmplienceHost } from "../../Components/helper.js";

const initTopSellers = () => {
    const topSellersList = document.querySelector("#top-seller-list");
    if (topSellersList) {
        loadSlick(topSellersList);
    }
};

const initRecommendations = () => {
    const recommendations = document.querySelector(".container--recentproducts");
    if (recommendations) {
        elementScrolledIntoView(recommendations, 300, loadSlick);
    }
};

const loadSlick = (element) => {

    const slickImages = element.querySelectorAll(".slick-images");
    if (slickImages && slickImages.length > 0) {
        slickImages.forEach(item => {
            if (item.classList.contains("loaded")) {
                return;
            }

            const mediaId = item.getAttribute("data-id");
            const amplienceUrl = `${AmplienceHost.setUrl}${mediaId}.jsonp?protocol=https`;

            fetch(amplienceUrl)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setupSlick(item, data);
                });
        });
    }
};

const setupSlick = (element, data) => {
    if (!element || !data)
        return;

    element.insertAdjacentHTML("beforeend", createHtmlFromAmplience(data));
    const slickJquery = $(element);

    slickJquery.slick({
        dots: false,
        swipe: false,
        arrows: false,
        autoplaySpeed: 900,
        fade: true,
        waitForAnimate: false
    }).hover(playSlick, pauseSlick);;

    element.classList.add("loaded");
};

const playSlick = (ele) => {
    const self = $(ele.currentTarget);
    self.addClass("playing");
    self.slickNext();
    self.slickPlay();
};

const pauseSlick = (ele) => {
    const self = $(ele.currentTarget);
    self.removeClass("playing");
    self.slickPause();
    if (self.slickCurrentSlide() !== 0) {
        self.slickGoTo(0);
    }
};

const createHtmlFromAmplience = (data) => {
    const template = "<div index='[index]'><picture><source type='image/webp' data-srcset='[url]?$categorypage_LowQ$&fmt=webp 177w,[url]?$categorypage_XXS$&fmt=webp 203w,[url]?$categorypage_XS$&fmt=webp 276w,[url]?$categorypage_S$&fmt=webp 364w,[url]?$categorypage_M$&fmt=webp 452w,[url]?$categorypage_L$&fmt=webp 552w, [url]?$categorypage_XL$&fmt=webp 728w, [url]?$categorypage_XXL$&fmt=webp 832w' /><img width='276' height='368' decoding='async' data-sizes='auto' class='lazyload img-responsive' data-srcset='[url]?$categorypage_LowQ$ 177w,[url]?$categorypage_XXS$ 203w,[url]?$categorypage_XS$ 276w,[url]?$categorypage_S$ 364w,[url]?$categorypage_M$ 452w,[url]?$categorypage_L$ 552w, [url]?$categorypage_XL$ 728w, [url]?$categorypage_XXL$ 832w'></picture></div>";
    let html = "";
    data.items.shift();
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].src.indexOf("_40") === -1 && data.items[i].type === "img") {
            html += template.replace("[index]", i).split("[url]").join(data.items[i].src);
        }
    }
    return html;
};

window.initTopSellersSlick = initTopSellers;
window.initRecommendationsSlick = initRecommendations;
//if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
//    init();
//} else {
//    document.addEventListener("DOMContentLoaded", init);
//}