import { imageSrcSet } from '../rr-helpers.js';
import { soldOutTemplate } from '../../soldout-template.js';

const addToCartPageTemplate = (items, strategyMessage, index) => {
    return `
        <h4 class="text-left">${strategyMessage}</h4>
        <div class="sell-slideshow" id="sellslick-${parseInt(index) + 1}">
            ${addToCartItemsTemplate(items)}
        </div>
    `;
}

const addToCartItemsTemplate = (products) => {
    let html = "",
        i = 0;

    for (let product of products) {
        //if (i == 4) break;

        let idArray = product.id.split("-"),
            articleId = idArray[0],
            colorId = idArray[1],
            productLink = product.attributes.link_url.toString().replace("nelly.com", window.location.host);

        let categoryGroup = product.categories.filter(({ id }) => id.includes("nelly_group")),
            categoryStyle = product.categories.filter(({ id }) => id.includes("nelly_style")),
            categoryID = "",
            categoryName = "";

        if (Array.isArray(categoryStyle) && categoryStyle.length > 0) {
            categoryID = categoryStyle[0].id;
            categoryName = categoryStyle[0].name;
        } else if (Array.isArray(categoryGroup) && categoryGroup.length > 0) {
            categoryID = categoryGroup[0].id;
            categoryName = categoryGroup[0].name;
        }

        html += `
            <div class="slide js-rr-recs-item GA_ProductData js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-shortsku="${product.id}" data-articlenr="${articleId}" data-colorid="${colorId}" data-brand="${product.brand}" data-name="${product.name}" data-price="" data-ordprice="" data-category="${categoryName}" data-categoryid="${categoryID}">
                <div class="image-container GA_Product">
                    <a href="${productLink}">
                        <picture>       
                            <source type="image/webp" data-srcset="${imageSrcSet(product.imageURL, true)}" />                                  
                            <img class="lazyload img-responsive" alt="${product.name}" data-sizes="auto" 
                                    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 212 282'%2F%3E"
                                    data-srcset="${imageSrcSet(product.imageURL)}">
                        </picture>
                    </a>
                    <a href="#" class="btn-quickadd btn-pink js-quickadd js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-categoryid="${categoryID}">
                        <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>
                    </a> 
                    ${soldOutTemplate(articleId, colorId)}
                </div>
                <a href="${productLink}" class="GA_Product">
                    <h5>
                        ${product.name}
                    </h5>
                    <span class="addtocart-slide-price-container js-rr-price-container">
                        <span style="visibility:hidden;">${product.priceCents}</span> 
                    </span>
                </a>
            </div>
            `
        i++;
    };
    return html;
}

const addToCartRecsPriceTemplate = (product) => {
    let html = "";
    html += `     
            ${product.IsDiscount ?
            `
                <span class="overview-original">${product.OrdinaryPrice}</span>&nbsp;       
                <span class="overview-sale">${product.Price}</span>&nbsp;                
                <span class="overview-percentage">${product.Discount}</span>
            ` :
            `
                <span class="overview-price">${product.Price}</span>
            `
        }
        `;
    return html;
};

const addToCartInitSlick = (placementElem) => {
    let addedToCartContainers = [];

    const quickAddUpsell = document.querySelector("#added_upsell");
    quickAddUpsell ? addedToCartContainers.push(quickAddUpsell) : null;

    const quickBuyContainer = document.querySelector("#quickBuyContainer");
    quickBuyContainer ? addedToCartContainers.push(quickBuyContainer) : null;

    const quickBuyAdded = document.querySelector("#quickbuy-added");
    quickBuyAdded && !quickBuyContainer ? addedToCartContainers.push(quickBuyAdded) : null;

    if (quickBuyContainer || quickBuyAdded)
        quickbuyAddedSlickSlider(addedToCartContainers, placementElem);
    else
        quickAddAddedSlickSlider(addedToCartContainers, placementElem);
};

export {
    addToCartPageTemplate,
    addToCartRecsPriceTemplate,
    addToCartInitSlick
};