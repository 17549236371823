import { loadScript } from './helper';

const nellyChannelId = $('#nelly_channelId').val();
const isLoggedIn = $('#isLoggedIn').val();
let isEbbotLoadinRequested = false;
let ebbotLoaded = false;

const loadChatWindow = () => {
    console.log("Ebbot init");
    window.Ebbot = { botId: window.nellyEBBOT.ebbotId, };

    ! function (t) {
        var e = "init-js-widget";
        if (!t.getElementById(e)) {
            var i = t.createElement("script");
            i.id = e, i.src = "https://ebbot-v2.storage.googleapis.com/ebbot-web/init.js?t=" + Math.random(), t.querySelector("body").appendChild(i)
        }
    }(document)

    window.Ebbot.onLoad = function (data) {
        window.Ebbot.closeChatWindow();
        if (isLoggedIn == 1) {
            window.Ebbot.setUserAttribute({ logged_in: "True" });
        } else {
            window.Ebbot.setUserAttribute({ logged_in: "False" });
        }
        ebbotLoaded = true;
    };

    let openChat = document.querySelector(".js-open-chat");
    if (openChat) {
        openChat.addEventListener("click", onChatOpenClick, true);
    }

    window.Ebbot.onDestroy = function () {
        if (openChat) {
            console.log("closing open chat")
            openChat.removeEventListener("click", onChatOpenClick, true);
        }
    };
}

const loadEbbotWidget = () => {
    setTimeout(() => {
        loadScript("https://static.zdassets.com/ekr/snippet.js?key=c98b27b8-3b22-4345-9f0b-bc7f2fa20bb0", loadChatWindow, true, "ebbot-snippet");
    }, window.nellyEBBOT.ebbotLoadingDelayCheckout || 10)
};

const onChatOpenClick = (event) => {
    if (window.Ebbot.isChatWindowClosed()) {
        window.Ebbot.openChatWindow();
    }
};

const onQuickTransformed = (event) => {
    if (window.Ebbot) {
        let showGotoCart = document.querySelector(".show-added-to-cart");
        let showSizes = document.querySelector(".show-sizes");
        if (showGotoCart || showSizes) {
            window.Ebbot.hide();
        } else {
            window.Ebbot.show();
        }
    }
};

const initEbbot = () => {
    if (window.nellyEBBOT && window.nellyEBBOT.ebbotEnabled == "true") {
        if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
            isEbbotLoadinRequested = true;
            loadEbbotWidget();
        } else {
            isEbbotLoadinRequested = true;
            document.addEventListener("DOMContentLoaded", loadEbbotWidget);
        }
    }
}

document.addEventListener('scroll', function (e) {
    if (window.pageYOffset >= 180 && isEbbotLoadinRequested == false) {
        initEbbot();
    }
}, true);

let isDocumentScorllable = document.body.parentElement.scrollHeight > document.body.parentElement.clientHeight;
if (isDocumentScorllable == false) {
    initEbbot();
}

document.addEventListener('transitionend', function (e) {
    if (window.Ebbot && ebbotLoaded == true) {
        //mini cart is open
        let miniCartOpen = document.querySelector('.minicart-open');

        //product list page size menu and cart open
        let showGotoCart = document.querySelector(".show-added-to-cart");
        let showSizes = document.querySelector(".show-sizes");

        //pdp page cart or size menu open
        let isSizeMenu = document.querySelector(".pdp-size-menu-container")
        let isSizeMenuClose = document.querySelector(".size-menu-close")
        let quickBuyOpen = document.querySelector("#quickbuy-added.open")

        if (miniCartOpen || showGotoCart || showSizes || (isSizeMenu && !isSizeMenuClose) || quickBuyOpen) {
            window.Ebbot.hide();
        } else if (window.Ebbot.isHidden() == true){
            window.Ebbot.show();
        }
    }
}, true);