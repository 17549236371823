const similarProductsTemplate = (shortSku, styleId, groupId, classId) => {
    if (!shortSku) return;
    const articlenr = shortSku.split('-')[0];
    const colorid = shortSku.split('-')[1];
    return `<a href="javascript:;" class="show-similar-btn js-show-similar" data-articlenr="${articlenr}" data-colorid="${colorid}" data-styleid="${styleId}" data-groupid="${groupId}" data-classid="${classId}">
        ${window.aliasTexts.showSimilarText}
    </a>`;
};

export {
    similarProductsTemplate
}