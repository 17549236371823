import { imageSrcSet } from '../rr-helpers.js';
import { soldOutTemplate } from '../../soldout-template.js';

const cartRecs1Template = (items, strategyMessage) => {
    return `

<div class="checkout-form" style="padding-left: 0; padding-right:0">
        <h4 class="checkout-heading" style="margin-top:0; margin-bottom: 10px; margin-left: 25px;">${strategyMessage}</h4>

        <div class="col-xs-12" style="background-color: white; padding-left: 7px;">
            <div class="checkout__kassa">
                <div class="checkout__upsells row" style="margin-left: 0; margin-right: 0;">
                    <div class="checkout__upsells__slide" data-quick-buy-url="${window.nellyRR.addProductInCartUrl}">
                        ${cartRecsItemsTemplate(items)}
                    </div>
                </div>
            </div>
        </div>
</div>
    `;
}

const cartRecsItemsTemplate = (products) => {
    let html = "",
        i = 0;

    for (let product of products) {
        //if (i == 4) break;

        let idArray = product.id.split("-"),
            articleId = idArray[0],
            colorId = idArray[1],
            productLink = product.attributes.link_url.toString().replace("nelly.com", window.location.host);

        let categoryGroup = product.categories.filter(({ id }) => id.includes("nelly_group")),
            categoryStyle = product.categories.filter(({ id }) => id.includes("nelly_style")),
            categoryID = "",
            categoryName = "";

        if (Array.isArray(categoryStyle) && categoryStyle.length > 0) {
            categoryID = categoryStyle[0].id;
            categoryName = categoryStyle[0].name;
        } else if (Array.isArray(categoryGroup) && categoryGroup.length > 0) {
            categoryID = categoryGroup[0].id;
            categoryName = categoryGroup[0].name;
        }

        html += `
            <div class="checkout__upsells__block col-xs-6 product-info js-rr-recs-item GA_ProductData" data-shortsku="${product.id}" data-articlenr="${articleId}" data-colorid="${colorId}" data-brand="${product.brand}" data-name="${product.name}" data-price="" data-ordprice="" data-category="${categoryName}" data-categoryid="${categoryID}" style="padding-right: 0;">
                <input type="hidden" class="GA_productDetail" data-articlenumber="${articleId}-${colorId}" data-name="${product.name}" data-price="" data-ordprice="" data-sessioncode="" data-brand="${product.brand}" data-category="" />

                <div class="checkout__upsells__bg clearfix">

                    <div class="checkout__upsells__image col-xs-12 col-sm-5">

                <button class="nnw-btn-like heart-like-rr js-fav">
                  <i class="js-rr-heart-container heart-like"
                                data-articlenr="${articleId}"
                                data-colorid="${colorId}"
                                data-isloggedin="${window.nellyRR.isLoggedIn}"></i>
                </button>

                        <a href="${productLink}" class="GA_Product js-rr-product-tracking" target="_blank" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}">
                            <picture>
                                <source type="image/webp" data-srcset="${imageSrcSet(product.imageURL, true)}" />                           
                                <img class="lazyload img-responsive" alt="${product.name}" data-sizes="auto" 
                                        src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 212 282'%2F%3E"
                                        data-srcset="${imageSrcSet(product.imageURL)}">
                            </picture>
                        </a>
                        <a href="javascript:;" class="hidden-sm hidden-md hidden-lg btn-quickadd btn-pink js-quickadd js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-categoryid="${categoryID}">
                            <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>
                        </a> 
                        ${soldOutTemplate(articleId, colorId)}
                    </div>

                    <div class="checkout__upsells__content col-xs-12 col-sm-7">
                        <div class="checkout__upsells__text-wrap">
                            <span class="global-brand nnw-black">${product.brand}</span>
                            <span class="global-title nnw-black">${product.name}</span>
                            <span class="js-rr-price-container">
                                <span style="visibility:hidden;">${product.priceCents}</span> 
                            </span>
                        </div>

<div class="desktop-fix">
                        <div class="select select-block hidden-xs">
                            <select class="js-select-size js-rr-select-sizes select-checkout checkout-select" name="Size"></select>
                        </div>

                        <div class="checkout__upsells__cta hidden-xs">
                            <a href="javascript:;" class="btn btn-add--upsells js-rr-product-tracking" data-sku="${product.id}" data-category-id="${categoryID}" data-trackingUrl="${product.clickTrackingURL}">
                                ${window.aliasTexts.addToCartText}                            <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>

                            </a>
                        </div>
</div>
                    </div>
                </div>
            </div>
            `;
        i++;
    };
    return html;
}

const cartRecsSizeTemplate = (availableSizes) => {
    let html = "";

    if (availableSizes && availableSizes.length > 0) {
        for (let size of availableSizes) {
            html += `
                <option value="${size.Id}">${size.Name}</option>
            `
        }
    } else {
        html = `<option value="-1" disabled selected>Soldout</option>`
    }

    return html;
}

// Sizes dropdown - Old solution backup - directly from RR response
//const cartRecsItemSizes = (sizeOption) => {
//    let html = "",
//        sortedOptions = [],
//        availableSizes = [];

//    sortedOptions = sizeOption.sort();
//    availableSizes = sortedOptions.filter(size => size.split("|")[2] == "True");

//    if (availableSizes.length > 0) {
//        for (let size of availableSizes) {
//            let sizeArray = size.split("|"),
//                sizeId = sizeArray[0],
//                sizeValue = sizeArray[1];
//            html += `
//                <option value="${sizeId}">${sizeValue}</option>
//            `
//        }
//    } else {
//        html = `<option value="-1" disabled selected>Soldout</option>`
//    }

//    return html;
//}

const cartRecsPriceTemplate = (product) => {
    let html = "";
    html += `     
            ${product.IsDiscount ?
            `
                <span class="price-original"><span>${product.OrdinaryPrice}</span></span>
                <span class="product-price price-sale" itemprop="price">
                    <span>${product.Price}</span>
                </span>                
                <span class="price-percentage">${product.Discount}</span>
            ` :
            `
                <span class="global-price"><span>${product.Price}</span></span>
            `
        }
        `
    return html;
}

const cartRecsInitSlick = () => {
    checkoutupsellSlide();
}

export {
    cartRecs1Template,
    cartRecsPriceTemplate,
    cartRecsInitSlick,
    cartRecsSizeTemplate
}