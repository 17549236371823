import { soldOutTemplate } from '../../soldout-template.js';

const returnRecsTemplate = (items, strategyMessage) => {
    return `
        <h3>${strategyMessage}</h3>
        <div class="flick-me">
            ${returnRecsItemTemplate(items)}
        </div>
    `;
}

const returnRecsItemTemplate = (products) => {
    let html = "";
    products.forEach(function (product) {

        let idArray = product.id.split("-"),
            articleId = idArray[0],
            colorId = idArray[1];

        let categoryGroup = product.categories.filter(({ id }) => id.includes("nelly_group")),
            categoryStyle = product.categories.filter(({ id }) => id.includes("nelly_style")),
            categoryID = "",
            categoryName = "";

        if (Array.isArray(categoryStyle) && categoryStyle.length > 0) {
            categoryID = categoryStyle[0].id;
            categoryName = categoryStyle[0].name;
        } else if (Array.isArray(categoryGroup) && categoryGroup.length > 0) {
            categoryID = categoryGroup[0].id;
            categoryName = categoryGroup[0].name;
        }

        html += `
            <div class="item js-rr-recs-item" data-shortsku="${product.id}">
                <div class="return-xsell-image-container">
                    <img class="img-responsive" src="${product.imageURL}&$categorypage_XS$" alt="${product.name}" />
                    <a href="#" class="return-xsell-quickview hide-quickbuy-mb js-quickbuy js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}">${ window.aliasTexts.quickViewText}</a>
                    <a href="#" class="btn-quickadd btn-pink js-quickadd js-rr-product-tracking" data-trackingUrl="${product.clickTrackingURL}" data-articlenr="${articleId}" data-colorid="${colorId}" data-categoryid="${categoryID}">
                        <i class="icon shopping-bag-add-white icon-add-to-shoppingbag"></i>
                    </a>     
                    ${soldOutTemplate(articleId, colorId)}
                </div>
                <div class="col-of-xs remove-margin">
                    <div class="col mobile-view">
                        <p class="product-name">
                            <span class="brand-name">${product.brand}</span>
                            <br />
                            ${product.name}
                        </p>
                        <p class="price js-rr-price-container">
                            <span style="visibility:hidden;">${product.priceCents}</span>
                        </p>
                    </div>
                </div>
            </div>
        `
    });
    return html;
}

const returnRecsInitFlickity = () => {
    document.querySelectorAll(".flick-me").forEach(slider => {
        let showPrevButton = () => {
            if (window.innerWidth < 769)
                return true
            else if (window.innerWidth < 991)
                return slider.querySelectorAll(".item").length > 2
            else
                return slider.querySelectorAll(".item").length > 4
        }
        new Flickity(slider, {
            contain: true,
            imagesLoaded: true,
            draggable: window.innerWidth < 769,
            pageDots: false,
            prevNextButtons: showPrevButton()
        })
    })
}

const returnRecsPriceTemplate = (product) => {
    let html = "";
    html += `     
            ${product.IsDiscount ?
            `
                <span class="discount">${product.Price}</span>
                <span class="strike-through">${product.OrdinaryPrice}</span>
                <span class="save">${product.Discount}</span>
            ` :
            `
                <span>${product.Price}</span>
            `
        }
        `
    return html;
}

export {
    returnRecsTemplate,
    returnRecsInitFlickity,
    returnRecsPriceTemplate
}
