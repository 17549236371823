import { isDev, queryStringBuilder, elementScrolledIntoView, getProductData, getProductDataHelo } from '../helper.js';
import { pageType, getRrRcs } from './rr-helpers.js';
import { homeRecsTemplate, homeRecsPriceTemplate } from './modules/rr-home-recs.js';
import { errorPageRecsTemplate, errorPageRecsPriceTemplate } from './modules/rr-error-page-recs.js';
import { categoryRecs1Template, categoryRecsRecentProductsTemplate, categoryRecsPriceTemplate } from './modules/rr-category-recs.js';
import { productPageRecsTemplate, productRecsPriceTemplate } from './modules/rr-product-recs.js';
import { addToCartPageTemplate, addToCartRecsPriceTemplate, addToCartInitSlick } from './modules/rr-addtocart-recs.js';
import { cartRecs1Template, cartRecsPriceTemplate, cartRecsInitSlick, cartRecsSizeTemplate } from './modules/rr-cart-recs.js';
import { returnRecsTemplate, returnRecsPriceTemplate, returnRecsInitFlickity } from './modules/rr-return-recs.js';
import { colorPicker, getColorImages } from '../color-picker.js';
import { searchRecsTemplate, searchRecsPriceTemplate } from './modules/rr-search-recs.js'; 
import { similarProductsTemplate } from '../similar-products-templates';

window.nellyRR = window.nellyRR || {};
let recsProductData = {};

const initRecs = (recsPlacements) => {
    const recsElements = document.querySelectorAll(".js-rr-recs-container");
    if (recsElements.length > 0) {
        recsPlacements.forEach((recsData, i) => {
            renderRecs(recsData, i);
        });
        if (recsProductData) {
            elementScrolledIntoView(recsElements, 300, recsElementReached);
        }
    }
};

//byygga ut med hearttemplate
const initProductData = (recs, placementElem, priceTemplate, callback, sizeTemplate, checkIfFavorite) => {
    if (recs) {
        const skuArray = recs.map(product => product.id);
        const onSuccess = function (data) {
            data.forEach(function (product) {
                let recsElems = placementElem.querySelectorAll(`.js-rr-recs-item:not(.data-loaded)[data-shortsku="${product.ShortSku}"]`);
                recsElems.forEach(function (recsElem) {
                    let priceContainer = recsElem.querySelector(".js-rr-price-container");
                    if (priceContainer) {
                        priceContainer.innerHTML = priceTemplate(product);
                        priceContainer.classList.add("price-loaded");
                    }
                    let colorPickerContainer = recsElem.querySelector(".js-color-picker");
                    if (colorPickerContainer) {
                        if (product.ColorPickerItems && product.ColorPickerItems.length > 0) {
                            colorPickerContainer.innerHTML = colorPicker(product.ColorPickerItems);
                        }
                    }
                    if (checkIfFavorite) {
                        const heartContainer = recsElem.querySelector(".js-rr-heart-container");
                        if (heartContainer && product.IsFavorite)
                            heartContainer.classList.add("heart-liked");                        
                    }
                    if (sizeTemplate) {
                        let sizeSelect = recsElem.querySelector(".js-rr-select-sizes");
                        if (sizeSelect) {
                            sizeSelect.innerHTML = sizeTemplate(product.Sizes);
                        }
                    }
                    const isActive = product.Active && (product.CommingSoon || product.InStock);
                    if (!isActive) {                       
                        recsElem.classList.add("product-soldout");
                        // Only show similar button if comingsoon false
                        if (!product.CommingSoon) {
                            const soldOutLabel = recsElem.querySelector(".product-soldout-label");
                            if (soldOutLabel) {
                                const similarHtml = similarProductsTemplate(product.ShortSku, product.StyleId, product.GroupId, product.ClassId);
                                
                                if (similarHtml) {
                                    soldOutLabel.insertAdjacentHTML('beforeend', similarHtml);
                                } 
                            }      
                        }
                    }
                    if (!product.InStock && product.CommingSoon) {
                        recsElem.classList.add("product-comingsoon");
                    }
                    recsElem.classList.add("data-loaded");
                });

            });  
            if (callback) {
                callback(placementElem);
            }
        }

        recsProductData[placementElem.getAttribute("data-placement")] = { "skuArray": skuArray, "onSuccess": onSuccess };            
    }
}

const recsElementReached = (elem) => {
    const placementName = elem.getAttribute("data-placement");
    if (recsProductData[placementName]) {

        console.log("placementName", placementName);

        if (placementName === "item_page.nelly_recs_1") {
            getProductDataHelo(recsProductData[placementName].skuArray, ["GetPrice", "GetColorVariations", "GetSimilar"], recsProductData[placementName].onSuccess);
        }
        else {
            getProductData(recsProductData[placementName].skuArray, ["GetPrice", "GetColorVariations", "GetSimilar"], recsProductData[placementName].onSuccess);
        }        
    } else {
        if (isDev) console.log(`[${placementName}] not found in recsProductData object`);
    }
}

const useAltImage = (altImage) => {
    if (!altImage || !altImage.length || altImage.length !== 33)
        return false;
    let res = altImage.split('_').map(function (d) { return nelly.parseDateTime(d, true); });
    let start = res[0];
    let end = res[1];
    return start <= new Date() && end >= new Date();
};

const altImageMapper = (product) => {
    if (product.attributes.altImage
        && product.attributes.altImage.length > 0
        && useAltImage(product.attributes.altImage[0])) {
        product.imageURL = product.imageURL.replace("?$", "_alt?$");
        product.altImageId = product.id + "_alt";
    } else {
        product.altImageId = product.id;
    }
    return product;
};

const renderRecs = (data, i) => {
    if (!data) return;

    let strategyMessage = data.strategyMessage;
    if (strategyMessage.includes("|")) {
        strategyMessage = strategyMessage.split("|")[0];
    }
    const placementName = data.placement;
    const items = data.recommendedProducts.map(altImageMapper);

    // Home
    if (data.placementType == pageType.Home) {
        const placementElem = document.querySelector(`[data-placement='${placementName}']`);
        if (placementElem) {
            placementElem.innerHTML = homeRecsTemplate(items, strategyMessage);
            productSlider();
            const onSuccess = function () {
                getColorImages();
                // had to use jquery here due to old code initializing this slider
                var $slider = $(placementElem).find(".product-slider__container");
                if ($slider) $slider.flickity("resize");            
            }
            initProductData(items, placementElem, homeRecsPriceTemplate, onSuccess);
        }
    // Category
    } else if (data.placementType == pageType.Category) {
        const placementElem = document.querySelector(`[data-placement='${placementName}']`);
        if (placementElem) {
            if (placementName == "category_page.nelly_recs_1") {
                placementElem.innerHTML = categoryRecs1Template(items, strategyMessage);
                window.initTopSellersSlick();
            }
            else if (placementName == "category_page.nelly_recenthistorical") {
                placementElem.innerHTML = categoryRecsRecentProductsTemplate(items, strategyMessage);
                window.initRecommendationsSlick();
            }
            initProductData(items, placementElem, categoryRecsPriceTemplate);
        }
    // Search 
    } else if (data.placementType == pageType.Search) {
        const placementElem = document.querySelector(`[data-placement='${placementName}']`);
        if (placementElem) {
            placementElem.innerHTML = searchRecsTemplate(items, "");
            productSlider();
            const onSuccess = function () {
                getColorImages();
                var $slider = $(placementElem).find(".product-slider__container");
                if ($slider) $slider.flickity("resize");
            };
            initProductData(items, placementElem, searchRecsPriceTemplate, onSuccess);
        }
    // Product
    } else if (data.placementType == pageType.Product) {
        const placementElem = document.querySelector(`[data-placement='${placementName}']`);
        if (placementElem) {
            // Replace recs_2 title with "Similar Products" instead of using the dynamic strategyMessage from RR.
            if (placementName == "item_page.nelly_recs_2") {
                strategyMessage = window.aliasTexts.showSimilarText;
            }
            placementElem.innerHTML = productPageRecsTemplate(items, strategyMessage);
            const onSuccess = function () {
                if (typeof window.xSellSliders != "undefined") {
                    getColorImages();
                    window.xSellSliders();
                }
            }
            initProductData(items, placementElem, productRecsPriceTemplate, onSuccess);          
        }
    // Add to cart
    } else if (data.placementType == pageType.AddToCart) {
        let placementElems;
        placementElems = document.querySelectorAll(`[data-placement='${placementName}']`);
        placementElems.forEach((placementElem) => {
            placementElem.innerHTML = addToCartPageTemplate(items, strategyMessage, i);
            initProductData(items, placementElem, addToCartRecsPriceTemplate, addToCartInitSlick);
        });
    // Cart
    } else if (data.placementType == pageType.Cart) {
        const placementElem = document.querySelector(`[data-placement='${placementName}']`);
        if (placementElem) {
            placementElem.innerHTML = cartRecs1Template(items, strategyMessage);
            initProductData(items, placementElem, cartRecsPriceTemplate, cartRecsInitSlick, cartRecsSizeTemplate, true);
        }
    // Wishlist - Not in use yet
    } else if (data.placementType == pageType.Wishlist) {
        //const placementElem = document.querySelector(`[data-placement='${placementName}']`);
        //if (placementElem) {
        //}
    }
    // Generic
    else if (data.placementType == pageType.Generic) {
        const placementElem = document.querySelector(`[data-placement='${placementName}']`);
        if (placementElem) {
            // Return Confirmed Recs
            if (document.querySelector(".rr-return-recs-1") != null) {
                placementElem.innerHTML = returnRecsTemplate(items, strategyMessage);
                returnRecsInitFlickity();
                initProductData(items, placementElem, returnRecsPriceTemplate);
            }            
        }
    } 
    // Error page (404)
    else if (data.placementType === pageType.ErrorPage) {
      const placementElem = document.querySelector(`[data-placement='${placementName}']`);
      if (placementElem) {
        placementElem.innerHTML = errorPageRecsTemplate(items, strategyMessage);
        productSlider();
        const onSuccess = function () {
          getColorImages();
          // had to use jquery here due to old code initializing this slider
          var $slider = $(placementElem).find(".product-slider__container");
          if ($slider) $slider.flickity("resize");
        }
        initProductData(items, placementElem, errorPageRecsPriceTemplate, onSuccess);
      }
    }
    else {
      if (isDev) console.log("No specific pageType found");
    }
};

const recsQueryParams = {
    "apiKey": window.nellyRR.apiKey,
    "apiClientKey": window.nellyRR.apiClientKey,
    "rid": window.nellyRR.regionId,
    "rfm": "LockedCampaigns:NotLocked",
    "rfb": "false",
    "filterAtr": "LockedCampaigns:\"NotLocked\"|ComingSoon:\"False\"",
    "excludeHtml": "true",
    "categoryData": "true"
};

const getRecommendationData = (placements, productId, categoryId) => {
    let queryParams = recsQueryParams;
    Object.assign(queryParams, { "placements": placements });
    if (productId) {
        Object.assign(queryParams, { "productId": productId });
        Object.assign(queryParams, { "atcid": productId });            
    }
    if (categoryId) {
        Object.assign(queryParams, { "chi": categoryId });    
    }
    // If logged in - send publicCustomerId
    if (window.nellyRR.isLoggedIn == "true" && typeof window.nellyRR.pcId != "undefined") {
        Object.assign(queryParams, { "userId": window.nellyRR.pcId });  
    }
    // GDPR - Only send sessionId and rcs if customer has accepted personalization
    // GDPR - add privateMode if customer hasn't accepted personalization
    if (window.nellyRR.hasAcceptedPersonalization == "true") {
        Object.assign(queryParams, { "sessionId": window.nellyRR.sessionId });
        Object.assign(queryParams, { "rcs": getRrRcs() });      
    } else {
        Object.assign(queryParams, { "privm": "t" });
    }

    let rr_cfrad = $.cookie("rr_cfrad");
    if (rr_cfrad && rr_cfrad.length > 2) {
        Object.assign(queryParams, { "cfrad": rr_cfrad });
    }

    let queryString = "?" + queryStringBuilder(queryParams);
    const baseUrl = "https://" + window.nellyRR.mode + ".richrelevance.com/rrserver/api/rrPlatform/recsForPlacements";
    let queryUrl = baseUrl + queryString;
    fetch(queryUrl).then(response => {
        response.json().then(data => {
            if (data && data.placements) {
                initRecs(data.placements);
            } else {
                console.log('failed to fetch recs');
                hideImgPlaceholders();
            }
        });
    });
}

const getRecommendationDataOnLoad = () => {
    let searchTermQueryString = ""; 
    if (window.nellyRR.searchTerm.length > 0) {
        searchTermQueryString = `&${queryStringBuilder({ "searchTerm": window.nellyRR.searchTerm })}`;
    }
    const queryUrl = window.nellyRR.recsForPlacementsApiUrl + searchTermQueryString;
    
    if (queryUrl) {
        fetch(queryUrl).then(response => {
            response.json().then(data => {
                renderRichRelevanceExperienceBrowser(data);
                if (data && data.placements) {
                    initRecs(data.placements);
                } else {
                    console.log('failed to fetch recs');
                    hideImgPlaceholders();
                }
            });
        });
    } else {
        if (isDev) console.log("No placements on load");
    }
}

const hideImgPlaceholders = () => {
    var imgPlaceholders = document.querySelectorAll(".rr-img-placeholder");
    imgPlaceholders.forEach((elem) => {
        elem.style.display = "none";
    });
};

const renderRichRelevanceExperienceBrowser = (response) => {
    let app, script;
    // Check if rr_annotations object was returned in the response
    if (response && response.rr_annotations) {
        // If we have rr_annotations_array push rr_annotations object there, otherwise create it with rr_annotations inside
        if (window.rr_annotations_array) {
            window.rr_annotations_array = window.rr_annotations_array.filter(el => el);
            window.rr_annotations_array.push(response.rr_annotations);
        } else {
            window.rr_annotations_array = [response.rr_annotations];
        }
        // Check if Experience Browser was already loaded on the page (it creates rr_experience_browser global variable)
        if (window.rr_experience_browser) {
            rr_experience_browser.start();
        } else {
            // If Experience Browser is missing, load it from RichRelevance cdn, it will start automatically and parse rr_annotations
            script = document.createElement("script");
            script.src = "//cdn.richrelevance.com/dashboard/applications/cfrad/dist/index.min.js";
            script.async = true;
            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }
};

export {
    initRecs,
    getRecommendationData,
    getRecommendationDataOnLoad
}