import { elementScrolledIntoView, AmplienceHost } from "./helper.js";
import { getMonitorSize } from "./monitor-size.js";

const init = () => {
    getColorImages();
    document.addEventListener("click", colorPickerShowMore, true);
    document.addEventListener("click", colorPickerComingSoon, true);
    //document.addEventListener("click", changeProductListItemColor, true);
};

const colorPicker = (colorPickerItems) => {
    var colorPicker = "";
    if (colorPickerItems && colorPickerItems.length > 0) {

        colorPicker = "<div class='overview-color'>";

        var totalCount = colorPickerItems.length,
            limit = 3,
            expandCount = totalCount - limit;

        colorPickerItems.forEach(function (variant) {
            var isWhite = variant.IsWhite ? " is-white" : "";
            var isComingSoon = (!variant.InStock && variant.ComingSoon) ? " is-comingsoon" : "";
            colorPicker += "<div class='overview-color-picker" + isWhite + isComingSoon + "' data-sku='" + variant.ShortSku.AsString + "'><a href='" + variant.ProductUrl + "'><img class='img-responsive' width='19' height='19' src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' /></a></div>";
        });

        if (totalCount > limit) {
            colorPicker += "<div class='overview-color-picker-more'><span class='overview-color-picker-text'>+" + expandCount + "</span></div>";
        }

        colorPicker += "</div>";
    }
    return colorPicker;
};

const colorPickerShowMore = (e) => {
    if (!e.target.closest(".overview-color-picker-more")) return;
    e.stopPropagation();
    var button = e.target.closest(".overview-color-picker-more"),
        colorPickerParent = button.closest(".overview-color");

    if (colorPickerParent)
        colorPickerParent.classList.add("expanded");
};

const colorPickerComingSoon = (e) => {
    if (!e.target.closest(".overview-color-picker.is-comingsoon")) return;
    e.stopPropagation();
    e.preventDefault();
    const colorPicker = e.target.closest(".overview-color-picker.is-comingsoon");
    const shortSku = colorPicker.getAttribute("data-sku");
    getMonitorSize(shortSku);
};

const changeProductListItemColor = (e) => {
    let pageTypeElement = document.getElementById('pageType') || {};
    if (pageTypeElement.value !== "productoverview") return;
    if (!e.target.closest(".overview-color-picker")) return;
    if (e.target.closest(".color-picker-link")) return;
    e.stopPropagation();
    e.preventDefault();  
    
    const actionUrl = document.getElementById('getProductListItemUrl').value;
    const listItemTileElement = e.target.closest(".GA_ProductData");
    const currentTrackingUrl = listItemTileElement.dataset.ticket;     
    const dataSku = e.target.closest(".overview-color-picker").dataset.sku; 
    const articleNr = dataSku.substr(0, dataSku.indexOf('-')); 
    const colorId = dataSku.substr(dataSku.indexOf('-') + 1); 

    $.ajax({
        type: "GET",
        data: { articleNr: articleNr, colorId: colorId },
        url: actionUrl,
        success: function (data) {            
            let listElementBody = new DOMParser().parseFromString(data, "text/html").body;                   
            
            listElementBody.querySelectorAll('[data-ticket]').forEach(item => item.dataset.ticket = currentTrackingUrl);
            listElementBody.querySelectorAll('[data-trackingurl]').forEach(item => item.dataset.trackingurl = currentTrackingUrl);
           
            listItemTileElement.outerHTML = listElementBody.outerHTML;
            getColorImages();    
        },
        error: function (xhr, status, err) {
            
        }
    });
};

const getColorImages = function () {
    
    const colorPickerContainer = document.querySelectorAll(".overview-color-picker:not(.element-lazyloaded)");

    if (colorPickerContainer.length > 0) {
        elementScrolledIntoView(colorPickerContainer, 1000, loadColorImages);
    }    
};

const loadColorImages = (item) => {

    const mediaId = item.getAttribute("data-sku");
    const reg = /^([0-9]{6,7}-[0-9a-zA-Z]{4})$/;

    if (!item.classList.contains("colorPickerDone") && mediaId.match(reg)) {

        item.classList.add("hidden");
        const colorImageUrl = AmplienceHost.setUrl + mediaId + ".json";
        
        fetch(colorImageUrl)
            .then(response => {
                return response.json();
            })
            .then(data => {
                data.items.forEach(function (imgItem) {
                    if (imgItem.src.indexOf("_40") > 0) {
                        const imgTag = item.querySelector("img");
                        if (imgTag) {
                            imgTag.src = AmplienceHost.iUrl + mediaId + "_40?$colorSwatch1x$";
                            item.classList.remove("hidden");
                        }
                    }                   
                });
                item.classList.add("colorPickerDone");
            });
    }
};

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", init);
}

window.loadColorImages = getColorImages;

export {
    colorPicker,
    getColorImages
};

