import debounce from 'lodash.debounce';
import { loadScript } from "./helper.js";

//const init = () => {

//};

const getSimilarProductsForQuickbuy = (element, articleNr, colorId) => {
    if (typeof window.nellyRR.getCTLandSimilarProductsUrl !== "undefined") {

        const routeUrl = window.nellyRR.getCTLandSimilarProductsUrl + `?articleNr=${articleNr}&colorId=${colorId}`;
        fetch(routeUrl).then(response => {
            response.json().then(data => {
                if (data && data.similarProducts) {
                    if (element) {
                        element.innerHTML = data.similarProducts;
                        const onLoad = () => {
                            window.xSellSliders();
                            window.addEventListener("resize", debounce(window.xSellSliders, 100));
                        };
                        loadScript("/Content/dist/productPage.js", onLoad);                         
                    }                                   
                }
            });
        });
    }
};

window.getSimilarProductsForQuickbuy = getSimilarProductsForQuickbuy;

//if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
//    init();
//} else {
//    document.addEventListener("DOMContentLoaded", init);
//}