import debounce from 'lodash.debounce';

const init = () => {
    // Add Class to body if balk is fixed or absolute
    var balk = document.querySelector(".balk-top");
    if (balk && balk.offsetHeight > 0) {
        var balkComputedStyle = window.getComputedStyle(balk);

        if (balkComputedStyle.getPropertyValue("position") == "absolute") {
            document.querySelector("body").classList.add("balk-is-absolute");
        }
        else if (balkComputedStyle.getPropertyValue("position") == "fixed") {
            document.querySelector("body").classList.add("balk-is-fixed");
        }

        window.addEventListener("resize", debounce(rePosition, 100));
        document.querySelectorAll(".balk-close-btn").forEach(elem => elem.addEventListener("click", closeBeam));
    }
};


const closeBeam = function (e) {

    document.querySelector("body").classList.add("balk-is-closed");
    document.querySelector("body").classList.remove("balk-top-of-page", "balk-is-fixed", "balk-is-absolute");

    var currentServerTime = document.querySelector("#currentServerTime");
    if (currentServerTime && currentServerTime.value) {
        var currentDate = nelly.parseDateTime(currentServerTime.value, true);
        var expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0);
        var saleBalkLink = document.querySelector(".balk-top .js-balk-link");
        if (saleBalkLink) {
            var balkId = saleBalkLink.getAttribute("data-id");
            $.cookie("balkClosed", balkId, { expires: expirationDate, path: "/" });
        }
        else {
            $.cookie("balkClosed", "returnBalk", { expires: expirationDate, path: "/" });
        }
        document.querySelector("#rr-beam")?.classList.remove("new-balk");
    }
}


const rePosition = function (e) {
    var balk = document.querySelector(".balk-top");
    if (balk && balk.offsetHeight > 0) {

        if (window.innerWidth >= 992) {
            document.querySelector("body").classList.add('balk-is-absolute');
            document.querySelector("body").classList.remove('balk-is-fixed');
        }
        else if (window.innerWidth < 992) {
            document.querySelector("body").classList.add('balk-is-fixed');
            document.querySelector("body").classList.remove('balk-is-absolute');
        }
    }
}


// Document ready
if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") init();
else document.addEventListener("DOMContentLoaded", init);